import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Api, SendEmail } from "./interface";
import { datePickerToShortDate, isDate, getDateTime } from "./date";

export default function EntityLocationScan(props) {
  const [parents, setParents] = useState([]);
  const [currentParent, setCurrentParent] = useState({
    EntityName: "No Parent",
    Entity_DBID: 0,
    Path: "No Parent",
  });
  // SSD serial numbers scanned
  const [bulkSNs, setBulkSNs] = useState([]);

  useEffect(() => {
    Api({
      sp: "getNonMaterialPaths",
      json: {},
    }).then((response) => {
      // get all possible locations and add a default location to the list
      response.unshift({
        EntityName: "No Parent",
        Entity_DBID: 0,
        Path: "No Parent",
      });
      setParents(response);
    });
  }, []);

  const updateLocation = (newValue) => {
    setCurrentParent(newValue);
  };

  const initiateLocationConfirmation = () => {
    //split serial numbers scanned
    let splitBulkSNs = bulkSNs.trim().split(/[\s|\t|,|;]+/);
    Api({
      sp: "initiateLocationConfirmationBySN",
      json: {
        parentId: currentParent.Entity_DBID,
        scannedSNs: splitBulkSNs,
        canEdit: props.canEdit,
      },
    }).then((response) => {
      // change the date time format
      response = response.map((row) =>
        isDate(row["Confirmation Date"])
          ? {
              ...row,
              "Confirmation Date": datePickerToShortDate(
                row["Confirmation Date"]
              ),
            }
          : row
      );
      // filter out the serial numbers that were sscanned but not forund in elm
      let snNotFound = splitBulkSNs.filter(
        (scannedSN) =>
          !response.map((row) => row["Serial Number"]).includes(scannedSN)
      );

      let columnHeaders = Object.keys(response[0]);
      //add the "not found" serial numbers in the data to highlight Location and Owner as "Not Found"
      //the csv should have all the scanned serial numbers including "Not Found" serial numbers
      snNotFound.forEach((unfoundSN) => {
        let row = {};
        columnHeaders.forEach((value) => (row[value] = "Not Found"));
        row = { ...row, "Serial Number": unfoundSN };
        response.push(row);
      });

      //prepare list of owners (as recipients) that have the drives (owned by them) scanned
      let recipients = [];
      recipients = Array.from(
        new Set(
          response
            //filter out the rows which were not moved because unauthorized
            .filter((row) => row["Confirmed Location"] !== "Not Authorized")
            .map((row) => row.Owner)
        )
      ).filter(
        //exclude the owners for drives that were not found
        //or if the action taker wasn't authorized for the location confirmation
        (email) => !["Not Found", "Not Authorized"].includes(email)
      );

      //send email with the recipients and JSON of the response
      SendEmail("instantEmailLocationConfirmed", {
        emailSubject: "Location Confirmed",
        confirmedlocation: currentParent.Path,
        recipients: recipients,
        emailAttachment: JSON.stringify(response),
        emailAttachmentName: "Location Confirmation_" + getDateTime() + ".csv",
      });

      //prepare csv to be downloaded for the action taker
      let csvContent = columnHeaders.join(",") + "\n";
      response.forEach((row) => {
        let csvRow = [];
        //Prepare header
        columnHeaders.forEach((fieldValue) => csvRow.push(row[fieldValue]));
        csvContent += csvRow.join(",") + "\n";
      });
      var blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8,",
      });

      // Programatically create a link and click it:
      var a = document.createElement("a");
      let fileName = "Location Confirmation" + getDateTime();
      a.href = URL.createObjectURL(blob);
      a.download = `${fileName}.csv`;
      a.click();
      //toast success when the selected serial numbers have been moved to a selected location and issue for location confirmation
      toast.success(
        "Location confirmation successfully initiated for " +
          response?.length +
          " item(s)!"
      );
      props.close();
    });
  };

  const disableConfirm = () => {
    return (
      !currentParent ||
      currentParent.Entity_DBID === 0 ||
      !bulkSNs ||
      bulkSNs?.length === 0
    );
  };

  return parents.length ? (
    <Modal
      size="lg"
      show={props.open}
      onHide={() => props.close()}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Initiate Location Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {parents.length ? (
          <Autocomplete
            autoHighlight={true}
            disablePortal
            id="parent-lookup"
            size="small"
            options={parents}
            disableClearable
            value={currentParent}
            sx={{ width: "100%" }}
            getOptionLabel={(option) => {
              return option.Path;
            }}
            onChange={(event, newValue) => {
              updateLocation(newValue);
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.Entity_DBID}>
                  {option.Path}
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Location" />}
          />
        ) : null}

        <Form.Group className="mb-3" controlId="bulk-group-input">
          <Form.Label>Enter/Scan serial numbers</Form.Label>
          <Form.Control
            autoFocus
            as="textarea"
            rows={3}
            onChange={(e) => setBulkSNs(e.target.value)}
            value={bulkSNs}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={() => props.close()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={(e) => {
            e.currentTarget.disabled = true;
            initiateLocationConfirmation();
          }}
          disabled={disableConfirm()}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
}
