import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import { Button, Form } from "react-bootstrap";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { Api } from "./interface";
import debounce from "lodash/debounce";

export default function ColumnFilter(props) {
  const [options, setOptions] = useState([]);
  const [bulk, setBulk] = useState(false);
  const [bulkText, setBulkText] = useState("");
  const [delimiter, setDelimiter] = useState({ val: ",", label: "Comma" });
  const [delimiters] = useState([
    { val: ",", label: "Comma" },
    { val: " ", label: "Space" },
    { val: " ", label: "Tab" },
    { val: ";", label: "Semicolon" },
    { val: "/[\r\n]+/", label: "Line Break" },
  ]);
  const [selected, setSelected] = useState(
    props.selected
      .filter((x) => x.key === props.column.id)
      .map((x) => ({ EntityMetaValue: x.label, DBID: x.DBID }))
  );
  const [currentOption, setCurrentOption] = useState([]);
  // const [currentSelection, setCurrentSelection] = useState([]);
  const [filterText, setFilterText] = useState("");
  let inputRef;

  const getOptionsDelayed = useCallback(
    debounce((text) => {
      setOptions([]);
      if (text.length) {
        Api({
          sp: "getMetaFilterByLevel",
          json: {
            level: props.level.length ? props.level : "Equipment",
            field: props.column.id,
            text: text,
          },
        }).then((response) => {
          const opts = response
            .filter((r) =>
              selected.every((s) => r.EntityMetaValue !== s.EntityMetaValue)
            )
            .slice(0, 10);

          setOptions(opts);
        });
      }
    }, 700),
    []
  );

  useEffect(() => {
    inputRef.focus();
    getOptionsDelayed(filterText, (filteredOptions) => {
      setOptions(filteredOptions);
    });
  }, [filterText, getOptionsDelayed]);

  const updateFields = () => {
    let sel = selected;
    let opt = currentOption.EntityMetaValue.trim();
    sel.push({
      EntityMetaValue: opt,
      DBID: currentOption.DBID,
    });
    setSelected(sel);
  };

  const onDelete = (val) => () => {
    setSelected((selected) =>
      selected.filter((v) => v.EntityMetaValue !== val)
    );
  };

  const updateCurrentOption = (e, o) => {
    setCurrentOption(o);
  };

  const toggleBulk = () => {
    setBulk(!bulk);
  };

  const applyBulk = () => {
    let bulkSelect = bulkText
      .split(delimiter.label === "Line Break" ? /[\r\n]+/ : delimiter.val)
      .map((x) => x.trim())
      .filter((x) => x)
      .map((x) => ({ EntityMetaValue: x }));
    let sel = [
      ...new Set(selected.concat(bulkSelect).map((x) => x.EntityMetaValue)),
    ].map((x) => ({ EntityMetaValue: x }));

    // get type DBIDs if necessary
    if (props.column.id === "Type") {
      Api({
        sp: "getEntityTypeIdByValue",
        json: {
          text: sel.map((x) => x.EntityMetaValue).join(),
        },
      }).then((response) => {
        sel.forEach((s) => {
          s.DBID = response.find(
            (x) =>
              x.EntityType.toLowerCase() === s.EntityMetaValue.toLowerCase()
          ).DBID;
          s.EntityMetaValue = response.find(
            (x) =>
              x.EntityType.toLowerCase() === s.EntityMetaValue.toLowerCase()
          ).EntityType;
        });
        setSelected(sel);
      });
    } else {
      setSelected(sel);
    }
  };

  const updateBulk = (event) => {
    setBulkText(event.target.value);
  };

  return (
    <Modal
      show={props.open}
      onHide={() => props.close(selected, props.column)}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Filter {props.column.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">Select values to filter column.</div>
        <Autocomplete
          size="lg"
          multiple
          filterSelectedOptions
          autoHighlight={true}
          id="filter-options"
          options={options}
          onHighlightChange={(e, o) => updateCurrentOption(e, o)}
          noOptionsText={"Start typing to search"}
          getOptionLabel={(option) => option.EntityMetaValue}
          onChange={(event, newValue) => updateFields(event, newValue)}
          onInputChange={(e, newInputValue) => setFilterText(newInputValue)}
          renderTags={() => null}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              autoFocus
              {...params}
              label="Filter"
              inputRef={(input) => {
                inputRef = input;
              }}
            />
          )}
        />
        <Box
          mt={1}
          className={"modal-box"}
          sx={{
            "& > :not(:last-child)": { mr: 1 },
            "& > *": { mr: 1, mt: 2 },
          }}
        >
          {selected.map((v) => (
            <Chip
              key={v.EntityMetaValue}
              label={v.EntityMetaValue}
              onDelete={onDelete(v.EntityMetaValue)}
            />
          ))}
        </Box>
        {bulk ? (
          <div className="mt-2">
            <Form.Control
              autoFocus
              as="textarea"
              rows={3}
              className="mb-3"
              onKeyUp={updateBulk}
            />
            <div className="d-flex justify-content-between">
              <Autocomplete
                size="small"
                autoHighlight={true}
                id="delimiter-options"
                value={delimiter}
                options={delimiters}
                noOptionsText={"Start typing to search"}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => setDelimiter(newValue)}
                style={{ width: "50%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Delimiter" />
                )}
              />
              <Button variant="primary" onClick={applyBulk}>
                Apply Bulk Filter
              </Button>
            </div>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={toggleBulk}>
          {bulk ? "Hide Bulk Edit" : "Bulk Edit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
