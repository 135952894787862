import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { Api } from "./interface";

class FilterCRUD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      filter: createFilterOptions(),
      filterText: "",
      currentFilter: { FilterName: "" },
    };
  }

  componentDidMount() {
    Api({ sp: "getUserFilters", json: {} }).then((response) => {
      const level = this.props.level.length ? this.props.level : "Equipment";
      const type = this.props.type;
      let filters = response.filter(
        (x) =>
          x.Level === level &&
          (level === "Material" ? x.EntityType === type : true)
      );
      this.setState({
        filters: filters,
        currentFilter:
          this.props.action === "load" ? filters[0] : { FilterName: "" },
      });
    });
  }

  render = () => {
    return (
      <Modal
        show={this.props.open}
        onHide={() => this.props.close()}
        className="entity-modal"
      >
        <Modal.Header closeButton="true">
          <Modal.Title>
            {this.props.action === "load" ? "Load" : "Save"}
            {" Filter"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.action === "save" ? (
            <Autocomplete
              autoHighlight={true}
              freeSolo={true}
              value={this.state.currentFilter ?? { FilterName: "" }}
              className="type-ahead"
              options={this.state.filters}
              getOptionLabel={(option) => option.FilterName}
              hiddenlabel="true"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Filter Name"
                  onChange={(e) =>
                    this.setState({ filterText: e.target.value })
                  }
                  autoFocus
                />
              )}
              onChange={(event, newValue) => {
                if (newValue.Filter) {
                  this.setState({
                    currentFilter: newValue,
                    filterText: newValue.FilterName,
                  });
                } else {
                  this.setState({
                    currentFilter: { FilterName: newValue },
                    filterText: newValue,
                  });
                }
              }}
              selectOnFocus
              handleHomeEndKeys
            />
          ) : (
            <Autocomplete
              autoHighlight={true}
              className="type-ahead"
              options={this.state.filters}
              value={this.state.currentFilter}
              getOptionLabel={(option) => option.FilterName}
              hiddenlabel="true"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Filter"
                  autoFocus
                />
              )}
              onChange={(_event, newValue) => {
                this.setState({ currentFilter: newValue });
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => this.props.close()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={
              !this.state.currentFilter?.FilterName?.length &&
              !this.state.filterText.length
            }
            onClick={(e) => {
              e.currentTarget.disabled = true;
              this.props.crudAction(
                this.props.action === "save" &&
                  !this.state.filters.includes(
                    (x) => x.FilterName === this.state.filterText
                  )
                  ? { FilterName: this.state.filterText }
                  : this.state.currentFilter,
                this.props.action
              );
            }}
          >
            {this.props.action === "load" ? "Load" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
}
export default FilterCRUD;
