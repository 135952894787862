import React, { Component } from "react";
import { Link, Envelope } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import { Hint } from "./constants.js";

export default class FieldInput extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = { hover: false, item: props.item };
  }

  openEmail = (item) => {
    window.open("mailto:" + item.EntityMetaValue);
  };

  openLink = (item) => {
    let link = item.EntityMetaValue;
    if (link.indexOf("https://") === -1 && link.indexOf("http://") === -1) {
      link = "http://" + link;
    }
    window.open(link);
  };

  handleChange = (e) => {
    // save if enter or tab is pressed
    if (e.keyCode === 13 || e.keyCode === 9) {
      this.props.update(e.target.value, this.state.item);
    }
  };

  handlePaste = () => {
    // this.props.update(event.clipboardData.getData("text"), this.state.item);
    // event.target.value = event.clipboardData.getData("text");
    // this.updateValue(event);
  };

  componentDidMount() {
    this.setState({ item: this.props.item });
  }

  componentDidUpdate(prevProps) {
    if (this.input?.current) {
      this.input.current.style.height = "auto";
      this.input.current.style.height = this.input.current.scrollHeight + "px";
    }

    if (prevProps !== this.props) {
      const val = this.props.item.EntityMetaValue;
      let item = this.props.item;
      item.OldVal = val;
      this.setState({ item: item });
    }
  }

  updateValue = (e) => {
    let item = this.state.item;
    item.OldVal = item.EntityMetaValue;
    item.EntityMetaValue = e.target.value;
    this.setState({ item: item });
  };

  saveValue = (e, item) => {
    // Only updated the EntityMetaValue if the OldVal is defined
    if (typeof item.OldVal !== "undefined") {
      item.EntityMetaValue = item.OldVal;
    }
    this.props.update(e, item);
  };

  render() {
    let buttonIcon =
      this.state.item.DataType === "URL" ? (
        <Hint
          placement="right"
          delay={{ show: 250, hide: 400 }}
          title="Click to visit url"
        >
          <Link
            onClick={() => this.openLink(this.state.item, this.props.entity)}
            color="grey"
            size={24}
            className={this.state.hover ? "showThis" : "hideThis"}
          />
        </Hint>
      ) : this.state.item.DataType === "Email" ? (
        <Hint
          placement="right"
          delay={{ show: 250, hide: 400 }}
          title={"Click to open email"}
        >
          <Envelope
            onClick={() => this.openEmail(this.state.item, this.props.entity)}
            color="grey"
            size={24}
            className={this.state.hover ? "showThis" : "hideThis"}
          />
        </Hint>
      ) : null;

    return (
      <>
        {this.props.disabled ? (
          <span className="disabled-meta">
            {this.state.item.EntityMetaValue}
          </span>
        ) : (
          <div
            className="w-100 h-100 form-control d-flex justify-content-between inputWithButton"
            onMouseOver={() => this.setState({ hover: true })}
            onMouseOut={() => this.setState({ hover: false })}
          >
            <Form.Control
              className="w-100 no-border overflow-hidden"
              ref={this.input}
              as="textarea"
              rows="1"
              placeholder={
                "Value" +
                (this.state.item.UnitShort
                  ? " (" + this.state.item.UnitShort + ")"
                  : "")
              }
              // need keyup for tab/enter detection, blur for save, and change for value control on local element
              onKeyUp={(e) => this.handleChange(e)}
              onPaste={(e) => this.handlePaste(e)}
              onBlur={(e) => this.saveValue(e.target.value, this.state.item)}
              onChange={(e) => this.updateValue(e)}
              value={this.state.item.EntityMetaValue || ""}
            />
            {buttonIcon}
          </div>
        )}
      </>
    );
  }
}
