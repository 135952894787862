import React, { Component } from "react";
import { Upload } from "./interface";
import Dropzone from "react-dropzone";
import { isImageFormat } from "./validator";
import { toast } from "react-toastify";

export class UploadImages extends Component {
  constructor(props) {
    super(props);

    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
    this.state = {
      currentFile: undefined,
      previewImage: undefined,
      uploading: false,
      imgServer: props.imgServer,
    };
    this.acceptFiles = { "image/*": [] };
  }

  selectFile = (event) => {
    this.setState({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
    });
  };

  upload = (file) => {
    if (file.length === 0 || !isImageFormat(file[0].name)) {
      toast.error("Accepted Formats: gif, jpeg, png, webp, bmp.");
    } else {
      this.setState({ uploading: true });
      this.uploading = true;
      Upload(file[0]).then((response) => {
        if (response.status === 200) {
          this.props.update(response.data[0].blobName, this.props.item);
          this.setState({ previewImage: "", uploading: false });
        }
      });
    }
  };

  render() {
    return (
      <a className="upload">
        <Dropzone onDrop={this.upload} accept={this.acceptFiles}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: "preview dropzone",
              })}
            >
              <input {...getInputProps()} />
              <span>Drag and drop file here, or click to select file</span>
              <br></br>
              {this.state.uploading && (
                <span style={{ color: "blue" }}>Uploading...</span>
              )}
            </div>
          )}
        </Dropzone>
      </a>
    );
  }
}
