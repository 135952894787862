const normalizeStatusData = (data) => {
  let sum = {};
  data.forEach((x) => {
    sum[x.key] = sum[x.key] ? sum[x.key] + x.data : x.data;
  });
  let total = data.length ? Object.values(sum).reduce((a, b) => a + b) : 0;
  return sum
    ? Object.entries(sum).map((x) => ({
        name: x[0],
        // convert to norm
        value: Math.round((x[1] / total) * 100),
      }))
    : null;
};
const prepareMultStatusData = (data, hostnames) => {
  let finalData = [];
  hostnames.forEach((host) => {
    let filteredData = data.filter((x) => x.EntityName === host.EntityName);
    let statusTimes = normalizeStatusData(filteredData);
    let statusUp = statusTimes.filter((x) => x.name === "Up")[0];
    let newData = {
      name: host.EntityName.replace(" ", "_"),
      value: statusUp ? statusUp.value : 0,
    };
    finalData.push(newData);
  });
  return finalData;
};

export { normalizeStatusData, prepareMultStatusData };
