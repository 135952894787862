import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
// import 'react-sortable-tree/style.css';
import "./components/treeStyle.css";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import EntitySearch from "./components/entitySearch";
import Splash from "./components/splash";
import EntityTypes from "./components/types";
import EntityConfig from "./components/fields";
import Templates from "./components/templates";
import MetaGroups from "./components/groups";
import Hierarchy from "./components/hierarchy";
import Events from "./components/events";
import { CookiesProvider } from "react-cookie";
import Admin from "./components/admin";
// import Search from "./components/search";
import Dash from "./components/dash";
import ImageView from "./components/imageView";
import PendigTrs from "./components/pendingTrs.js";
import HealthCheck from "./components/healthCheck.js";
import TabularReports from "./components/tabularReports.js";
import ApiTest from "./components/apiTest";
import UserPreferences from "./components/userPreferences.js";
import ReleaseNotes from "./components/releaseNotes.js";

import AdvancedSearch from "./components/advancedSearch";
import TransactionsList from "./components/transactionsList";
import ApiManagement from "./components/apiManagement";
import ApiDocs from "./components/apiDocs";
import DefaultFields from "./components/defaultFields";
import StatusWorkflow from "./components/statusWorkflow";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./components/secureRoute.js";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);
const routeName = window.location.pathname.toLowerCase();
const isKiosk = routeName.includes("/kiosk");

const oktaAuth = new OktaAuth({
  issuer: "https://nandps.okta.com/" + "/oauth2/default",
  clientId: "0oa9srm6se1db6mKq697",
  redirectUri: window.location.origin + "/login/callback",
});

const triggerLogin = async () => {
  await oktaAuth.signInWithRedirect();
};

// eslint-disable-next-line no-unused-vars
const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  window.location.replace(
    toRelativeUrl(originalUri || "/", window.location.origin)
  );
};

const customAuthHandler = async () => {
  const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
  if (!previousAuthState || !previousAuthState.isAuthenticated) {
    // App initialization stage
    await triggerLogin();
  }
};

root.render(
  <CookiesProvider>
    <BrowserRouter>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Routes>
          {/* authenticated endpoints */}
          <Route path="" element={<RequiredAuth />}>
            <Route path="/" element={<App isKiosk={isKiosk} />}>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="entitySearch" element={<EntitySearch />} />
              <Route path="types" element={<EntityTypes />} />
              <Route path="fields" element={<EntityConfig />} />
              <Route path="templates" element={<Templates />} />
              <Route path="groups" element={<MetaGroups level="Group" />} />
              <Route path="home" element={<Splash />} />
              <Route path="hierarchy" element={<Hierarchy />} />
              <Route path="events" element={<Events />} />
              <Route path="admin" element={<Admin />} />
              <Route path="dash" element={<Dash EntityName="Test Name" />} />
              <Route path="tabularReports" element={<TabularReports />} />
              <Route path="userPreferences" element={<UserPreferences />} />
              <Route path="releaseNotes" element={<ReleaseNotes />} />
              <Route
                path="img"
                element={<ImageView EntityName="Test Name" />}
              />
              <Route
                path="equipment"
                element={<AdvancedSearch level="" key="Equipment" />}
              />
              <Route
                path="location"
                element={<AdvancedSearch level="Location" key="Location" />}
              />
              <Route
                path="site"
                element={<AdvancedSearch level="Site" key="Site" />}
              />
              <Route
                path="geo"
                element={<AdvancedSearch level="Geo" key="Geo" />}
              />
              <Route
                path="building"
                element={<AdvancedSearch level="Building" key="Building" />}
              />
              <Route
                path="floor"
                element={<AdvancedSearch level="Floor" key="Floor" />}
              />

              <Route
                path="lab"
                element={<AdvancedSearch level="Lab" key="Lab" />}
              />
              <Route
                path="material"
                element={<AdvancedSearch level="Material" key="Material" />}
              />
              <Route
                path="apiManagement"
                element={<ApiManagement level="" key="apiManagement" />}
              />
              <Route
                path="apiDocs"
                element={<ApiDocs level="" key="apiDocs" />}
              />
              <Route
                path="ssd"
                element={
                  <AdvancedSearch level="Material" key="SSD" type="SSD" />
                }
              />
              <Route
                path="component"
                element={
                  <AdvancedSearch
                    level="Material"
                    key="Component"
                    type="Component"
                  />
                }
              />
              <Route
                path="statusWorkflow"
                element={<StatusWorkflow key="Workflow" />}
              />

              {/* Transactions */}
              {/* <Route
            path="transactions"
            element={<TransactionsList matType="SSD" />}
          /> */}
              <Route
                path="transactions/ssd"
                element={<TransactionsList matType="SSD" />}
              />
              <Route
                path="transactions/component"
                element={<TransactionsList matType="Component" />}
              />

              <Route path="apitest" element={<ApiTest />} />

              {/* Kiosk */}
              <Route
                path="kiosk"
                element={<Navigate to="/kiosk/transactions/ssd" />}
              />

              <Route
                path="kiosk/transactions/ssd"
                element={<TransactionsList matType="SSD" isKiosk={isKiosk} />}
              />

              <Route
                path="kiosk/transactions/component"
                element={
                  <TransactionsList matType="Component" isKiosk={isKiosk} />
                }
              />
              <Route
                path="default"
                element={<DefaultFields isKiosk={routeName === "/default"} />}
              />
            </Route>
          </Route>

          <Route path="login/callback" element={<LoginCallback />} />
        </Routes>
      </Security>
      {/* unauthenticated endpoints */}
      <Routes>
        <Route path="pendingTrReport" element={<PendigTrs />} />
        <Route path="health" element={<HealthCheck />} />
      </Routes>
    </BrowserRouter>
  </CookiesProvider>
);
