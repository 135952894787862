import React, { useState } from "react";
import SortableTreeWithoutDndContext from "react-sortable-tree";
import "react-sortable-tree/style.css";

const createTree = (props) => {
  let tree = { included: [], excluded: [] };
  props.template.forEach((x) => {
    if (x.EntityMetaType && x.EntityType_DBID === props.entityTypeId) {
      x["title"] = x.EntityMetaType;
      x["children"] = [];
      x["expanded"] = true;
      x["included"] = true;
      tree.included.push(x);
    }
  });
  props.meta
    .filter((x) => {
      return !props.template.find((y) => {
        return y.EntityMetaType === x.EntityMetaType;
      });
    })
    .forEach((x) => {
      if (x.EntityMetaType) {
        x["title"] = x.EntityMetaType;
        x["children"] = [];
        x["expanded"] = true;
        x["included"] = false;
        tree.excluded.push(x);
      }
    });

  // createNest(tree.included);

  makeTree(tree.included);

  // sort tree
  tree.included = sortNest(tree.included);

  // sort alphabetically
  tree.excluded.sort((a, b) =>
    a.EntityMetaType?.toLowerCase() > b.EntityMetaType?.toLowerCase() ? 1 : -1
  );
  return tree;
};

const sortNest = (array) => {
  array.sort((a, b) => (a.Sort > b.Sort ? 1 : -1));
  array.forEach((a) => {
    if (a.children && a.children.length > 0) {
      sortNest(a.children);
    }
  });
  return array;
};

const getParentById = (arr, itemId) =>
  arr.reduce((a, item) => {
    if (a) return a;
    if (item.EntityMetaType_DBID === itemId) return item;
    if (item["children"]) return getParentById(item["children"], itemId);
  }, null);

const makeTree = (tree) => {
  let children = [];
  // eslint-disable-next-line no-unused-vars
  tree.forEach((x, i) => {
    if (x.ParentEntityMetaType_DBID !== 0) {
      let parent = getParentById(tree, x.ParentEntityMetaType_DBID);
      // tree = tree.filter(f => f.Entity_DBID !== x.Entity_DBID);
      parent.children.push(x);
      children.push(x);
    }
  });

  // remove all children from root
  children.forEach((x) => {
    for (let i = tree.length - 1; i >= 0; i--) {
      if (tree[i].EntityMetaType_DBID === x.EntityMetaType_DBID) {
        tree.splice(i, 1);
      }
    }
  });
};

function Tree(props) {
  const tree = createTree(props);
  const [treeData, setTreeData] = useState(tree.excluded);
  const [treeData2, setTreeData2] = useState(tree.included);

  const dropAllowed = (data, included) => {
    return (
      ((!data.nextParent || data.nextParent.IsGroup) && included) ||
      (!data.nextParent && !included)
    );
  };

  const setExcludedData = (data) => {
    setTreeData(data);
  };

  const setIncludedData = (data) => {
    props.colChange(data);
    setTreeData2(data);
  };

  return (
    <div className="pb-2 position-relative w-100 justify-content-between d-flex">
      <div className="poisiton-absolute top-0 start-0 tree-container">
        <h4>Excluded</h4>
        <SortableTreeWithoutDndContext
          isVirtualized={false}
          canDrag={!props.readOnly}
          canDrop={(data) => dropAllowed(data, false)}
          treeData={treeData}
          dndType="NEW_NODE"
          onChange={(data) => setExcludedData(data)}
        />
      </div>
      <div className="position-absolute top-0 start-50 tree-container">
        <h4>Included</h4>
        <SortableTreeWithoutDndContext
          isVirtualized={false}
          canDrag={!props.readOnly}
          canDrop={(data) => dropAllowed(data, true)}
          treeData={treeData2}
          dndType="NEW_NODE"
          onChange={(data) => setIncludedData(data)}
        />
      </div>
    </div>
  );
}

export default Tree;
