import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Api } from "./interface";

const Timeline = (props) => {
  const [dates, setDates] = useState([]);

  const timespanConvert = (d) => {
    return {
      y: parseInt(d / 365),
      w: parseInt((d % 365) / 7),
      d: (d % 365) % 7,
    };
  };

  const formatDayDiff = (timeSpan) => {
    return (
      (timeSpan.y ? `${timeSpan.y} Year${timeSpan.y === 1 ? "" : "s"}, ` : "") +
      (timeSpan.w ? `${timeSpan.w} Week${timeSpan.w === 1 ? "" : "s"}, ` : "") +
      `${timeSpan.d} Day${timeSpan.d === 1 ? "" : "s"}`
    );
  };

  useEffect(() => {
    Api({
      sp: "getEntityDates",
      json: { entityId: props.item.Entity_DBID },
    }).then((response) => {
      setDates(response);
    });
  }, [props.item]);

  return (
    <div className="text-center p-2">
      <h4>Timeline</h4>
      <hr />
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{ minWidth: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Event</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Event Delta</TableCell>
              <TableCell align="center">Total Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dates.map((row, i) => {
              const currentDtm = new Date();
              const dtm = new Date(row.Dtm);

              const diffTime = Math.abs(currentDtm - dtm);
              const diffInDays =
                Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

              // const diffInTime = currentDtm.getTime() - dtm.getTime();
              // const diffInDays = diffInTime / (1000 * 3600 * 24);

              const dtmStr = `${
                dtm.getMonth() + 1
              }/${dtm.getDate()}/${dtm.getFullYear()}`;

              const eventDelta = timespanConvert(row.TimeSpan);
              const eventDeltaStr = formatDayDiff(eventDelta);

              const totalTimeSpan = timespanConvert(diffInDays);
              const totalTimeSpanStr = formatDayDiff(totalTimeSpan);
              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.Event}</TableCell>
                  <TableCell align="center">{dtmStr}</TableCell>
                  <TableCell align="center">{eventDeltaStr}</TableCell>
                  <TableCell align="center">{totalTimeSpanStr}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Timeline;
