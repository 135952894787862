import { useRef } from "react";
import LineGraph from "./lineGraph";
import PieGraph from "./pieGraph";
import { BarChart, AreaChart } from "reaviz";
import { Link } from "react-router-dom";
import { BoxArrowUpRight } from "react-bootstrap-icons";
const Graph = (props) => {
  const height = props.width;
  const width = props.width;
  const data = props.data;
  // const legends = props.legends;
  const elementRef = useRef(null);
  return data.length ? (
    props.type === "Bar" ? (
      <BarChart ref={elementRef} height={height} width={width} data={data} />
    ) : props.type === "Area" ? (
      <AreaChart ref={elementRef} width={width} height={height} data={data} />
    ) : props.type === "Donut" ? (
      <div className="graph" ref={elementRef}>
        <PieGraph
          data={data}
          height={elementRef.current?.clientHeight}
          width={elementRef.current?.clientWidth}
        />
      </div>
    ) : props.type === "Line" ? (
      <div className="graph" ref={elementRef}>
        <LineGraph
          data={data}
          height={elementRef.current?.clientHeight}
          width={elementRef.current?.clientWidth}
          yLabel="Daily Utilization (Minutes)"
        />
      </div>
    ) : props.type === "Normalized" ? (
      <div className="graph" ref={elementRef}>
        <LineGraph
          data={data}
          height={elementRef.current?.clientHeight}
          width={elementRef.current?.clientWidth}
          yLabel="% Utilization Daily"
        />
        <Link className="report-footer" to={"/reports/" + props.hostName}>
          <BoxArrowUpRight className="context-icon" />
        </Link>
      </div>
    ) : null
  ) : null;
};

export default Graph;
