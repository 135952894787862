//  NOTE: The reportJson includes the following properties of interest:
//  {
//     "reportType": "Entities Created",
//     "reportParameters": {
//         "timeHorizon": "Day"
//         "locationIds": [2],
//         "actionByIds": [3, 4],
//         "entityTypeIds": [5],
//         "entityIds": [6,7,8],
//         "metaFilters": [{metaType: "Model", metaTypeId: 6, metaValues: ["Advantest, B6700L"]},
//                         {metaType: "Business Alignment", metaTypeId: 129, metaValues: ["NAND-DTM", "IOG"]}]
//     }
// }

const BuildReportQuery = (reportJson) => {
  // SELECT & FROM STATEMENTS
  let selectStatement = "";
  let fromStatement = "";

  switch (reportJson.reportType) {
    case "Entities Created":
      selectStatement =
        "SELECT v.CreatedDtm AS `Created Date`, v.EntityName AS `Entity Name`, v.EntityType AS `Entity Type`, v.CreatedBy AS `Created By`, v.Location, CONCAT(return_elm_instance(),'dash?id=', v.Entity_DBID) AS `Link` ";
      fromStatement = "FROM vEntitiesCreatedInLastMonth v ";
      break;

    case "Entities Updated":
      selectStatement =
        "SELECT v.CreatedDtm AS `Updated Date`, v.EntityName AS `Entity Name`, v.EntityType AS `Entity Type`, v.EntityMetaType AS `Field`, v.EntityMetaValue AS `Value Changed To`, v.UpdatedBy AS `Updated By`, v.Location, CONCAT(return_elm_instance(),'dash?id=', v.Entity_DBID) AS `Link` ";
      fromStatement = "FROM vEntitiesUpdatedInLastMonth v ";
      break;

    case "Entities Moved":
      selectStatement =
        "SELECT v.CreatedDtm AS `Moved Date`, v.EntityName AS `Entity Name`, v.EntityType AS `Entity Type`, v.MovedTo AS `Moved To`, v.MovedBy AS `Moved By`, v.Location, CONCAT(return_elm_instance(),'dash?id=', v.Entity_DBID) AS `Link` ";
      fromStatement = "FROM vEntitiesMovedInLastMonth v ";
      break;
  }

  // REPORT PARAMETERS
  let params = reportJson.reportParameters;

  // WHERE STATEMENT
  const whereStatement = "WHERE TRUE ";

  // AND STATEMENTS
  let andStatement = "";

  // Frequency
  switch (params.timeHorizon) {
    case "Day":
      andStatement += "AND v.CreatedDtm > (UTC_TIMESTAMP() - INTERVAL 1 DAY) ";
      break;

    case "Week":
      andStatement += "AND v.CreatedDtm > (UTC_TIMESTAMP() - INTERVAL 1 WEEK) ";
      break;

    case "Month":
      // Already covered by the views
      break;
  }

  // Location
  // For every ID, use RLIKE with the id in the following pattern '481 >|> 481 >'
  // Entities will either be one level below a geo i.e. '481 >' or further down '> 481 >'
  if (params.locationIds && params.locationIds.length > 0) {
    let locationIds = params.locationIds;
    let locationIdsRegex = [];

    locationIds.forEach((id) => {
      locationIdsRegex.push(`^${id} >`); // Regex uses ^ for first character
      locationIdsRegex.push(`> ${id} >`);
    });

    locationIdsRegex = locationIdsRegex.join("|");

    andStatement += `AND v.LocationIdPath RLIKE '${locationIdsRegex}' `;
  }

  // NOTE: Could add another use case for "Moved To" - use AND MovedTo LIKE '%2103 - Char Lab%'
  // NOTE: Alternative approach if you want to use LIKE - use ID to lookup the name of location and then stack multiple LIKE statements

  // Action By (Created By, Updated By, Moved By)
  if (params.actionByIds && params.actionByIds.length > 0) {
    let actionByIds = params.actionByIds.map((id) => `'${id}'`).join(", ");
    andStatement += `AND v.User_DBID IN (${actionByIds}) `;
  }

  // Entity Type
  if (params.entityTypeIds && params.entityTypeIds.length > 0) {
    let entityTypeIds = params.entityTypeIds.join(", ");
    andStatement += `AND v.EntityType_DBID IN (${entityTypeIds}) `;
  }

  // Entity ID - NOTE: Non-distinct entity names makes this tough - push out to later
  // if (params.entityIds && params.entityIds.length > 0) {
  //   let entityIds = params.entityIds.join(", ");
  //   andStatement += `AND v.Entity_DBID IN (${entityIds}) `;
  // }

  // Parameterized Metadata Filters
  // NOTE: This does not currently work with nested groups and is limited to AND operation on multiple filters
  if (params.metaFilters && params.metaFilters.length > 0) {
    let firstMetaType = "";
    let selectFromStatement = "";
    let selfJoinStatements = "";
    let whereStatement = "WHERE TRUE ";
    let andMetaStatements = "";

    params.metaFilters.forEach((f, i) => {
      let metaType = f.metaType;
      let metaTypeId = f.metaTypeId;
      let metaValues = f.metaValues // Escape all symbols, add single quotes, and comma separate meta values
        .map((v) => `'${v.replace(/[^\w\s]/gi, "\\$&")}'`)
        .join(", ");
      // Use v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') instead if you face any issues

      if (i === 0) {
        firstMetaType = `${metaType}`;
        selectFromStatement = `SELECT \`${metaType}\`.Entity_DBID FROM tEntityMeta \`${metaType}\` `;
        andMetaStatements = `AND \`${metaType}\`.EntityMetaType_DBID = ${metaTypeId} AND \`${metaType}\`.EntityMetaValue IN (${metaValues}) `;
      } else {
        // Need an self join and 2 AND statements for every additonal key/values metadata pair
        selfJoinStatements += `INNER JOIN tEntityMeta \`${metaType}\` ON \`${firstMetaType}\`.Entity_DBID = \`${metaType}\`.Entity_DBID `;
        andMetaStatements += `AND \`${metaType}\`.EntityMetaType_DBID = ${metaTypeId} AND \`${metaType}\`.EntityMetaValue IN (${metaValues}) `;
      }
    });

    let metaFilterSql =
      selectFromStatement +
      selfJoinStatements +
      whereStatement +
      andMetaStatements;

    andStatement += `AND v.Entity_DBID IN (${metaFilterSql}) `;
  }

  // ORDER BY
  const orderByStatement = "ORDER BY v.CreatedDtm DESC";

  // Build the final SQL
  let reportSQL =
    selectStatement +
    fromStatement +
    whereStatement +
    andStatement +
    orderByStatement;

  return reportSQL.trim();
};

export { BuildReportQuery };
