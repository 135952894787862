import axios from "axios";
import { oven } from "./common";

const bake = (c, v) => {
  return oven({}, true, c, v);
};

const host = window.location.hostname.includes("elm.")
  ? "https://swt-elm-server-prod.azurewebsites.net"
  : window.location.hostname.includes("elm-qa")
  ? "https://swt-elm-server-qa.azurewebsites.net"
  : // : "https://swt-elm-api.azure-api.net/api";
    "https://swt-elm-server.azurewebsites.net";

// const host = "https://swt-elm-server-qa.azurewebsites.net";

// const host = "http://localhost:8080";

const ocpKey = "2a7bf31c098847e7943379cc001814d4";

const Api = async (body) => {
  body.json.user = !bake("isKiosk")
    ? bake("user")
      ? bake("user")["oid"]
      : ""
    : bake("kioskUserOID");

  return axios
    .post(host + "/api", body, {
      headers: {
        "Ocp-Apim-Subscription-Key": ocpKey,
        // "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => response.data)
    .catch((err) => console.error(err));
};

const Cosmos = async (body) => {
  body.user = bake("user")["oid"];

  return axios
    .post(host + "/cosmos", body, {
      headers: {
        "Ocp-Apim-Subscription-Key": ocpKey,
      },
    })
    .then((response) => response.data)
    .catch((err) => console.error(err));
};

const Query = async (body) => {
  body.user = bake("user")["oid"];
  return axios
    .post(host + "/query", body, {
      headers: {
        "Ocp-Apim-Subscription-Key": ocpKey,
      },
    })
    .then((response) => response.data)
    .catch((err) => console.error(err));
};

const QueryTable = async (body) => {
  body.user = bake("user")["oid"];
  return axios
    .post(host + "/querytable", body, {
      headers: {
        "Ocp-Apim-Subscription-Key": ocpKey,
      },
    })
    .then((response) => response.data)
    .catch((err) => console.error(err));
};

const SendEmail = async (emailStoredProcedure, emailParams, interrupt) => {
  let emailObj = {};

  // Run the stored procedure associated with email using emailParams
  Api({
    sp: emailStoredProcedure,
    json: emailParams,
  }).then((response) => {
    // Save the database response to an email object
    // add email params sent at the initiation call, in case of attachment
    emailObj = {
      emailSubject: response[0].EmailSubject,
      emailContent: response[0].EmailContent,
      emailRecipients: response[0].EmailRecipients,
      emailAttachment: emailParams.emailAttachment ?? " ",
      emailAttachmentName: emailParams.emailAttachmentName ?? " ",
    };

    if (interrupt) {
      interrupt.callBack();
    }
    // Call the Azure logic app with the emailObj to send the email
    return axios
      .post(bake("instantEmailEndpoint"), emailObj)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  });
};

// api to get sas query string for azure blob access
const Sas = async () => {
  return axios
    .get(host + "/sas", {
      headers: {
        "Ocp-Apim-Subscription-Key": ocpKey,
      },
    })
    .then((response) => response.data)
    .catch((err) => console.error(err));
};

const Upload = async (file) => {
  let formData = new FormData();
  formData.append(file.name, file);
  return await axios
    .post(host + "/upload", formData, {
      // .post("https://localhost:8080/upload", formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Credentials": true,
        "Ocp-Apim-Subscription-Key": ocpKey,
        supports_credentials: true,
      },
    })
    .then((response) => response)
    .catch((response) => response.statusText);
};

const DownloadExcel = async (body) => {
  body.json.user = bake("user")["oid"];
  return axios
    .post(host + "/downloadExcel", body, {
      headers: {
        "Ocp-Apim-Subscription-Key": ocpKey,
      },
      responseType: "arraybuffer",
    })
    .then((response) => response)
    .catch((err) => console.error(err));
};

// const Delete = async (imgServer, fileName, sas) => {
//   let formData = new FormData();
//   formData.append(fileName, fileName);
//   formData.append("sas", sas);
//   return await axios
//     .post(imgServer + "imagedelete", formData, {
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Content-Type": "multipart/form-data",
//         "Access-Control-Allow-Credentials": true,
//         supports_credentials: true,
//       },
//     })
//     .then((response) => response.statusText)
//     .catch((response) => response.statusText);
// };

export {
  Api,
  Cosmos,
  Sas,
  Upload,
  Query,
  QueryTable,
  SendEmail,
  DownloadExcel,
};
