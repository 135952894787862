import { useLocation, useNavigate } from "react-router-dom";

export default function NavTabs(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const lastMenuItemStyle = { paddingRight: "0px" };

  return (
    <div className="mb-2 d-flex justify-content-between">
      {props.navItems.map((navItem, i) => (
        <div
          key={navItem.name}
          className={
            location.pathname === navItem.route ? "nav-tab-active" : "nav-tab"
          }
          style={i === props.navItems.length - 1 ? lastMenuItemStyle : {}}
          onClick={() => {
            navigate(navItem.route);
          }}
        >
          {navItem.name}
        </div>
      ))}
    </div>
  );
}
