import React from "react";
import { useEffect, useState } from "react";
import TransactionModal from "./transactionModal";

export default function GiveIt(props) {
  const [list, setList] = useState([]);

  const [headers, setHeaders] = useState(null);
  const [body, setBody] = useState({});

  useEffect(() => {
    setList(props.list);

    const headers =
      props.matType === "SSD"
        ? [
            { label: "Serial Number", field: "Serial Number", edit: false },
            { label: "Build ID", field: "Build ID", edit: false },
          ]
        : props.matType === "Component"
        ? [
            {
              label: "Lot ID",
              field: "Component Lot ID",
              show: true,
              edit: false,
            },
            {
              label: "Quantity",
              field: "Quantity",
              show: true,
              edit: props.type === "Got" ? false : true,
            },
          ]
        : null;
    setHeaders(headers);
  }, []);

  const updateBody = (newBody) => {
    setBody(newBody);
  };

  return (
    <TransactionModal
      headers={headers}
      title={props.type}
      type={props.type}
      list={list ?? props.list}
      summaryData={list}
      body={body}
      updateBody={(x) => updateBody(x)}
      show={props.show}
      close={(refresh) => props.close(refresh)}
      txnType={"GiveIt"}
      matType={props.matType}
    ></TransactionModal>
  );
}
