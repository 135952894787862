import { useEffect, useState, useRef } from "react";
import { Hint, ROWSPERPAGE } from "./constants";
import {
  Divider,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  ChatLeft,
  PersonDash,
  PersonPlus,
  PersonCheck,
  Clipboard2Minus,
  Clipboard2Plus,
  Clipboard2Check,
  List,
  Trash,
  Grid3x3,
  PiggyBank,
} from "react-bootstrap-icons";
import { Api, SendEmail } from "./interface";
import { getComparator, handleResize, idleTimeout, oven } from "./common.js";
import Modal from "react-bootstrap/Modal";
import { Button, ButtonGroup, Form, Stack } from "react-bootstrap";

import { toast } from "react-toastify";
import { shortDate, shortDtm } from "./date";
import TransactionRequest from "./transactionRequest";

import BadgeVerify from "./badgeVerify";
import { IsRole } from "./validator";
import TransactionModal from "./transactionModal";
import NavTabs from "./navTabs.js";
import TransactionDetails from "./transactionDetails";

function TransactionsList(props) {
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Transaction_DBID");
  const [rows, setRows] = useState([]);
  const [filteredRows, setfilteredRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentModal, setCurrentModal] = useState(null);

  const [filterToggle, setFilterToggle] = useState(null);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [locations, setLocations] = useState([]);

  const [summaryData, setSummaryData] = useState(null);

  const [currentRow, setCurrentRow] = useState(null);

  const [isKiosk, setIsKiosk] = useState(props.isKiosk);
  const [badge, setBadge] = useState("");

  const [headers, setHeaders] = useState(null);
  const [body, setBody] = useState(null);
  const badgeRef = useRef(badge);
  const setBadgeRef = (data) => {
    badgeRef.current = data;
    setBadge(badgeRef.current);
  };
  const kiosk = "kiosk";
  const completeStatus = ["Complete", "Declined"];
  const columnsToHide = [
    "CurrentOID",
    "NewOID",
    "CreatedOID",
    "New Location",
    "Path",
    "TransactionIdentifier",
    "DueDate",
    "ChildEntity_DBID",
  ];
  if (props.matType === "SSD") {
    columnsToHide.push("Component Lot ID");
  }

  const [actionContextAnchor, setActionContextAnchor] = useState(null);

  const handleIdle = () => {
    idleTimer.reset();

    if (isKiosk) {
      logout();
      window.location.reload(false);
    }
  };
  // timeout after 10 minutes of inactivity and page refresh
  const { idleTimer } = idleTimeout({ onIdle: handleIdle, idleTime: 600 });

  const bake = (g, c, v) => {
    if (typeof g === "string") {
      return oven({ level: "transactions" }, false, g, c);
    } else {
      return oven({ level: "transactions" }, true, c, v);
    }
  };

  const logout = () => {
    let kioskUser = {
      name: "kiosk",
      unique_name: "kiosk",
      oid: "kiosk",
    };
    bake({}, "user", kioskUser);
    bake({}, "kioskUserOID", "kiosk");
  };

  const openDetails = () => {
    setActionContextAnchor(null);
    setCurrentModal("Details");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    bake("rowsPerPage", parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const openHistory = () => {
    setActionContextAnchor(null);
    getHistory(currentRow);
  };

  const openDelete = () => {
    setActionContextAnchor(null);
    setCurrentModal("Delete");
  };

  const setTransactionHeaders = (row) => {
    let initHeaders =
      row.Type === "SSD"
        ? [
            {
              label: "Build ID",
              field: "Build ID",
              show: true,
              edit: false,
              sortable: true,
            },
            {
              label: "Quantity",
              field: "RequestedQuantity",
              show: true,
              edit: false,
              requestModal: true,
            },
            {
              label: "Status",
              field: "Status",
              show: row.Status === "Complete",
              edit: false,
            },
          ]
        : row.Type === "Component"
        ? [
            {
              label: "Lot ID",
              field: "Component Lot ID",
              show: true,
              edit: false,
              requestModal: true,
              sortable: true,
            },
            {
              label: "Quantity",
              field: "RequestedQuantity",
              show: true,
              edit: false,
            },
            {
              label: "Status",
              field: "Status",
              show: row.Status === "Complete",
              edit: false,
            },
          ]
        : null;

    setHeaders(initHeaders);
    return initHeaders;
  };

  const handleRowClick = (e, row) => {
    setTransactionHeaders(row);
    row = {
      ...row,
      EntityOwner: [
        users
          ?.find((user) => user["OID"] === row["CurrentOID"])
          ?.User_DBID.toString(),
      ],
    };

    setCurrentRow(row);

    if (row.Status === "Request") {
      setCurrentModal("RequestEdit");
    }
    if (isKiosk) {
      setCurrentModal("BadgeVerify");
    } else if ((isKiosk && badgeRef.current !== "") || !isKiosk) {
      getRowData(row);
    }
  };

  const getRowData = (row) => {
    let field = setTransactionHeaders(row)?.filter(
      (header) => header.sortable
    )?.[0]?.field;

    let newBody = {
      ...body,
      entityType: row.Type,
      transactionId: row["Transaction_DBID"],
      status: row.Status,
      newOwner: users.find((item) => item.UserName === row["New Owner"]),
    };
    if (row.Status !== "Complete") {
      Api({
        sp: "getTransactionData",
        json: { transactionId: parseInt(row["Transaction_DBID"], 10) },
      }).then((response) => {
        response = response.sort((a, b) => {
          return a[field] > b[field] ? 1 : -1;
        });

        setSummaryData(response);
        let initAdditionalFields = [];

        initAdditionalFields =
          row.Type === "SSD"
            ? [
                {
                  fieldType: "list",
                  fieldMeta: "Serial Number",
                  fieldName: "Selected List",
                },
              ]
            : null;

        newBody = {
          ...newBody,
          additionalFields: initAdditionalFields,
          comment: row["Comments"],
          // for loan transactions, set new location
          newLocation:
            row.Status === "Loan"
              ? locations.find(
                  (parent) => parent.Path === response[0]["New Location"].trim()
                )
              : null,
        };
        setBody(newBody);
        if (row.Status === "Ship") {
          Api({
            sp: "getTransactionExternalData",
            json: { transactionId: parseInt(row["Transaction_DBID"], 10) },
          }).then((response) => {
            newBody = {
              ...newBody,
              CompanyName: response[0].CompanyName,
              ContactName: response[0].ContactName,
              Email: response[0].Email,
              Address: response[0].Address,
              ShippingNumber: response[0].ShippingNumber,
              comment: response[0]?.comments,
            };
            setBody(newBody);
            setCurrentModal("Edit");
          });
        } else {
          setBody(newBody);
          setCurrentModal("Edit");
        }
      });
    }
    if (row.Status === "Complete") {
      Api({
        sp: "getTransactionCompletedData",
        json: { transactionId: parseInt(row["Transaction_DBID"], 10) },
      }).then((response) => {
        response = response.sort((a, b) => {
          return a[field] > b[field] ? 1 : -1;
        });
        setSummaryData(response);
        newBody = {
          ...newBody,
          comment: response[0]?.comments,
        };
        setBody(newBody);
        setCurrentModal("Edit");
      });
    }
  };

  const verifyBadge = (b) => {
    if (allUsers.some((x) => x.Badge.includes(b))) {
      let user = allUsers.filter((x) => x.Badge.includes(b))[0];
      setBadgeRef(b);
      toast.success("Badge Verified Succesfully for " + user.UserName);
      closeModal();
      getRowData(currentRow);
      bake({}, "kioskUserOID", user["OID"]);
    } else {
      toast.error("Badge Not Found");
    }
  };

  const addBadge = (body) => {
    Api({
      sp: "addBadge",
      json: body,
    }).then((response) => {
      response.forEach((x) => {
        let val = x["Badge"]?.split(",");
        x["Badge"] = val?.length > 0 ? val : [""];
      });
      let user = response.filter((x) => x.Badge.includes(body.badge))[0];
      let badgeType = !body.badgeType ? "" : "Temporary ";
      toast.success(
        badgeType + "Badge added successfully for " + user?.UserName
      );

      //update user id to capture the badge owner in history
      bake({}, "kioskUserOID", user["OID"], {
        sameSite: "lax",
        secure: true,
      });
      setAllUsers(response);
      closeModal(true);
      getRowData(currentRow);
    });
  };

  const closeModal = (refresh) => {
    setBody({});
    setSummaryData(null);
    setCurrentModal(null);
    if (refresh) {
      getData();
    }
  };

  const getData = () => {
    Api({
      sp: "getTransactions",
      json: { transactionType: props.matType },
    }).then((response) => {
      response.forEach((r) => {
        r["Created Date"] = shortDate(r["Created Date"]);
        r.Total = parseInt(r.Total?.toString()) ?? 0;

        let value = r["TransactionIdentifier"];
        r["TransactionIdentifier"] = value?.split(",");

        r["Transaction_DBID"] = r["Transaction_DBID"]
          .toString()
          .padStart(8, "0");
        r["Path"] = r["Current Location"];
      });

      setRows(response);

      const alternateColumnHeaders = {
        "Current Location": "Location",
        Transaction_DBID: "Transaction ID",
        LoanDueDate: "Loan Due Date",
      };

      const buildColumnDefinition = (field) => {
        return {
          id: field,
          label: alternateColumnHeaders[field] ?? field,
          display: !columnsToHide.includes(field),
        };
      };

      const getColumnDefinitions = (record) => {
        let keys = Object.keys(record);

        const transactionTypeSpecificFields = {
          SSD: "Build ID",
          Component: "Component Name",
        };

        let additionalColumns = [];
        additionalColumns.push({
          id: "TransactionIdentifier",
          label: transactionTypeSpecificFields[record.Type],
          display: true,
        });

        additionalColumns.push({
          id: "Actions",
          label: "Actions",
          display: true,
        });

        return [
          ...keys.map((field) => buildColumnDefinition(field)),
          ...additionalColumns,
        ];
      };

      let cols = getColumnDefinitions(response[0]);

      cols = cols.map((col) => {
        return col.id === "LoanDueDate" ? { ...col, display: false } : col;
      });
      setColumns(cols);
      filterRowsByText(response);
    });
  };

  useEffect(() => {
    let rpp = parseInt(bake("rowsPerPage"), 10);
    rpp = isNaN(rpp) ? 15 : rpp;
    setRowsPerPage(rpp);
    handleResize();
    Api({
      sp: "getNonMaterialPaths",
      json: {},
    }).then((parents) => {
      setLocations(parents);
    });
    Api({
      sp: "getUsers",
      json: {},
    }).then((users) => {
      users.forEach((x) => {
        let val = x["Badge"]?.split(",");
        x["Badge"] = val?.length > 0 ? val : [""];
      });

      // Remove Material Storage as new owner on Got It for regular users
      let nonClassStoreUsers = users.filter(
        (u) => u.UserName !== "Material Storage"
      );
      setAllUsers(users);
      setUsers(IsRole("Material Storage") ? users : nonClassStoreUsers);
    });
  }, []);

  useEffect(() => {
    if (props.isKiosk) {
      setIsKiosk(props.isKiosk);
      setFilterToggle(kiosk);
      getData();
    }
  }, [isKiosk]);

  useEffect(() => {
    getData();
  }, [filterText, filterToggle, props.matType]);

  const filterRowsByText = (unfilteredRows) => {
    let f = unfilteredRows ?? rows;
    let filterToggled = isKiosk ? kiosk : filterToggle;
    if (!filterText?.length) {
      setfilteredRows(f);
    } else {
      f = f.filter((o) => {
        return Object.keys(o).some((k) => {
          if (!k.includes("OID")) {
            return o[k]
              ?.toString()
              .toLowerCase()
              .includes(filterText.toLowerCase());
          }
        });
      });
      setfilteredRows(f);
    }
    filterRows(filterToggled, f);
  };

  useEffect(() => {
    handleResize();
  });

  const toggleFilter = (filter) => {
    if (filterToggle === filter) {
      setFilterToggle(null);
      filter = null;
    } else {
      setFilterToggle(filter);
    }
  };

  const filterRows = (filter, r) => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (!filter) {
      r = r.filter((x) => !completeStatus.includes(x.Status));
    } else if (filter === "check") {
      r = r.filter(
        (x) =>
          x["CreatedOID"] === user.oid && !completeStatus.includes(x.Status)
      );
    } else if (filter === "plus") {
      r = r.filter(
        (x) => x["NewOID"] === user.oid && !completeStatus.includes(x.Status)
      );
    } else if (filter === "minus") {
      r = r.filter(
        (x) =>
          x["CurrentOID"] === user.oid && !completeStatus.includes(x.Status)
      );
    } else if (filter === kiosk) {
      r = r.filter(
        (x) =>
          x["Current Owner"] === "Material Storage" && x.Status === "Give It"
      );
    } else if (filter === "Complete") {
      r = r.filter((x) => completeStatus.includes(x.Status));
    } else if (filter === "Loan") {
      r = r.filter((x) => ["Loan", "Return Loan"].includes(x.Status));
      let cols = columns.map((col) => {
        return col.id === "LoanDueDate" ? { ...col, display: true } : col;
      });
      setColumns(cols);
    } else {
      r = r.filter((x) => x.Status === filter);
    }
    setfilteredRows(r);
  };

  const headerSort = (event) => {
    let label = event.target.innerText;
    let column = columns.find((column) => column.label === label);

    if (column.id === "History") {
      return;
    }

    let dir =
      orderBy === column.id ? (order === "asc" ? "desc" : "asc") : "asc";
    setOrder(dir);
    setOrderBy(column.id);
    filteredRows.sort(getComparator(dir, column.id));
  };

  const getHistory = (row) => {
    Api({
      sp: "getTransactionHistory",
      json: { id: parseInt(row["Transaction_DBID"]) },
    }).then((response) => {
      response.forEach((r) => {
        r["Timestamp"] = shortDtm(r["Timestamp"]);
      });
      setHistory(response);
      setCurrentRow(row);
      setCurrentModal("History");
    });
  };

  const handleContextClick = (event, row) => {
    setCurrentRow(row);
    event.stopPropagation();
    setActionContextAnchor(event.target);
  };

  const deleteRow = () => {
    SendEmail(
      "instantEmailGiveGotRequest",
      {
        emailSubject: "Request " + currentRow["Transaction_DBID"] + " Canceled",
        transactionId: parseInt(currentRow["Transaction_DBID"], 10),
        status: "Canceled",
      },
      { callBack: deleteCallback }
    );
  };

  const deleteCallback = () => {
    closeModal();
    Api({
      sp: "deleteTransaction",
      json: { id: parseInt(currentRow["Transaction_DBID"], 10) },
    }).then(() => {
      toast.success("Request " + currentRow["Transaction_DBID"] + " canceled");
      getData();
    });
  };

  const updateBody = (newBody) => {
    setBody(newBody);
  };

  return (
    <div className="main-container">
      <div className="mt-2 d-flex justify-content-between align-items-center">
        <h2>{props.matType + " Transactions"}</h2>

        {/* Use only the transactions pages for Kiosk */}
        {isKiosk ? (
          <NavTabs
            navItems={[
              { name: "SSD", route: "/kiosk/transactions/ssd" },
              { name: "COMPONENT", route: "/kiosk/transactions/component" },
            ]}
          />
        ) : (
          <NavTabs
            navItems={[
              { name: "INVENTORY", route: "/" + props.matType.toLowerCase() },
              {
                name: "TRANSACTIONS",
                route: "/transactions/" + props.matType.toLowerCase(),
              },
            ]}
          />
        )}
      </div>
      <Stack direction="horizontal" gap={3}>
        <Form.Control
          placeholder="Search"
          value={filterText}
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
        />

        {!isKiosk ? (
          <ButtonGroup>
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Toggle Give Its Created By Me"
            >
              <Button
                size="sm"
                onClick={() => toggleFilter("check")}
                variant={
                  filterToggle === "check" ? "primary" : "outline-primary"
                }
              >
                <PersonCheck size={28} />
              </Button>
            </Hint>
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Toggle Give Its From Me"
            >
              <Button
                size="sm"
                onClick={() => toggleFilter("minus")}
                variant={
                  filterToggle === "minus" ? "primary" : "outline-primary"
                }
              >
                <PersonDash size={28} />
              </Button>
            </Hint>
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Toggle Give Its To Me"
            >
              <Button
                size="sm"
                onClick={() => toggleFilter("plus")}
                variant={
                  filterToggle === "plus" ? "primary" : "outline-primary"
                }
              >
                <PersonPlus size={28} />
              </Button>
            </Hint>
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Toggle Requests"
            >
              <Button
                size="sm"
                onClick={() => toggleFilter("Request")}
                variant={
                  filterToggle === "Request" ? "primary" : "outline-primary"
                }
              >
                <Clipboard2Minus size={28} />
              </Button>
            </Hint>
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Toggle Give Its"
            >
              <Button
                size="sm"
                onClick={() => toggleFilter("Give It")}
                variant={
                  filterToggle === "Give It" ? "primary" : "outline-primary"
                }
              >
                <Clipboard2Plus size={28} />
              </Button>
            </Hint>
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Toggle Loans"
            >
              <Button
                size="sm"
                onClick={() => toggleFilter("Loan")}
                variant={
                  filterToggle === "Loan" ? "primary" : "outline-primary"
                }
              >
                <PiggyBank size={28} />
              </Button>
            </Hint>
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Toggle Completed"
            >
              <Button
                size="sm"
                onClick={() => toggleFilter("Complete")}
                variant={
                  filterToggle === "Complete" ? "primary" : "outline-primary"
                }
              >
                <Clipboard2Check size={28} />
              </Button>
            </Hint>
          </ButtonGroup>
        ) : null}
      </Stack>
      <Paper sx={{ width: "100%" }}>
        <TableContainer
          component={Paper}
          className="tableContainer"
          id="tab-container"
        >
          <Table stickyHeader aria-label="sticky table">
            {/* Column Headers */}
            <TableHead>
              <TableRow>
                {columns
                  .filter((column) => column.display)
                  .map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.numeric ? "right" : "left"}
                      padding={column.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      {" "}
                      {column.id !== "History" ? (
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          className={"header-sort-icon"}
                        >
                          <span
                            className={"col-header table-header pr-2"}
                            onClick={headerSort}
                          >
                            {column.label}
                          </span>
                        </TableSortLabel>
                      ) : (
                        <span
                          className={"col-header table-header pr-2"}
                          onClick={headerSort}
                        >
                          {column.id}
                        </span>
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            {/* Table Content */}
            <TableBody>
              {filteredRows
                .slice()
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id) => {
                  return (
                    <TableRow
                      className="giveitListTable pointer"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={id}
                      onClick={(e) => handleRowClick(e, row)}
                    >
                      {columns
                        .filter((column) => column.display)
                        .map((column) => {
                          const value = row[column.id];
                          return column.id === "History" ? (
                            <TableCell
                              key={column.id}
                              align="center"
                              onClick={(e) => openHistory(e, row)}
                            >
                              <ChatLeft color="blue" size={18} />
                            </TableCell>
                          ) : column.id === "Actions" ? (
                            <TableCell
                              key={column.id}
                              align="center"
                              // onClick={(e) => openHistory(e, row)}
                              onClick={(e) => handleContextClick(e, row)}
                            >
                              <List
                                size={24}
                                className="pointer row-menu-icon"
                                align="center"
                              />
                            </TableCell>
                          ) : ["New Location", "Current Location"].includes(
                              column.id
                            ) ? (
                            <TableCell key={column.id} align={column.align}>
                              {" "}
                              {value
                                ?.split(" > ")
                                .slice(0)
                                .map((v, i) => {
                                  return (
                                    <span key={i} className="search-meta-value">
                                      {"" + v}
                                    </span>
                                  );
                                })}
                            </TableCell>
                          ) : ["TransactionIdentifier"].includes(column.id) ? (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className="giveit-buildid-wrap"
                            >
                              {" "}
                              <div className="transactions-build-id mb-2">
                                <br />
                                {Array.isArray(value)
                                  ? value.length > 1
                                    ? value.map((v, i) => {
                                        return (
                                          <span
                                            key={i}
                                            className="search-meta-value"
                                          >
                                            {"" + v}
                                          </span>
                                        );
                                      })
                                    : value
                                  : value}
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          labelRowsPerPage="Rows:"
          rowsPerPageOptions={ROWSPERPAGE}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>{" "}
      {currentModal === "History" ? (
        <Modal
          show={currentModal === "History"}
          onHide={closeModal}
          className="entity-modal"
          size="xl"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>History {currentRow["Transaction_DBID"]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="history-modal-box">
              <Paper sx={{ width: "100%" }}>
                <TableContainer
                  component={Paper}
                  className="tableContainer"
                  id="tab-container"
                >
                  <Table stickyHeader aria-label="sticky table">
                    {/* Column Headers */}
                    <TableHead>
                      <TableRow>
                        {Object.keys(history[0])
                          .map((x) => ({ id: x }))
                          .map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.numeric ? "right" : "left"}
                              padding={
                                column.disablePadding ? "none" : "normal"
                              }
                            >
                              <span className={"col-header table-header pr-2"}>
                                {column.id}
                              </span>
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>

                    {/* Table Content */}
                    <TableBody>
                      {history.map((row, id) => {
                        return (
                          <TableRow
                            className="pointer"
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                          >
                            {Object.keys(history[0])
                              .map((x) => ({ id: x }))
                              .map((column) => {
                                const value = row[column.id];
                                return column.id === "Location" ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {value?.split(" > ").map((v, i) => {
                                      return (
                                        <span
                                          key={i}
                                          className="search-meta-value"
                                        >
                                          {"" + v}
                                        </span>
                                      );
                                    })}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      {currentModal === "Edit" ? (
        <TransactionModal
          headers={headers}
          show={currentModal === "Edit"}
          title={
            currentRow.Status === "Complete"
              ? "Request " + currentRow["Transaction_DBID"]
              : currentRow.Status === "Give It"
              ? "Got It " + currentRow["Transaction_DBID"]
              : "Got It (" +
                currentRow.Status +
                ") " +
                currentRow["Transaction_DBID"]
          }
          close={(refresh) => closeModal(refresh)}
          type={currentRow.Status}
          summaryData={summaryData}
          txnType={"GotIt"}
          matType={props.matType}
          currentRow={currentRow}
          body={body}
          updateBody={(x) => updateBody(x)}
        ></TransactionModal>
      ) : null}
      {currentModal === "Details" ? (
        <TransactionDetails
          show={currentModal === "Details"}
          closeModal={closeModal}
          row={currentRow}
        />
      ) : null}
      {currentModal === "RequestEdit" ? (
        <TransactionRequest
          editing={currentModal === "RequestEdit"}
          closeModal={closeModal}
          locations={locations}
          headers={headers}
          users={allUsers}
          currentRow={currentRow}
          matType={props.matType}
        />
      ) : null}
      {currentModal === "BadgeVerify" ? (
        <BadgeVerify
          verifying={currentModal === "BadgeVerify"}
          users={allUsers}
          verifyBadge={(b) => verifyBadge(b)}
          closeModal={() => closeModal()}
          addBadge={(body) => addBadge(body)}
        />
      ) : null}
      {currentModal === "Delete" ? (
        <Modal
          show={currentModal === "Delete"}
          onHide={closeModal}
          className="entity-modal"
        >
          <Modal.Header>
            <Modal.Title>Confirm Cancel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentRow.Status} {currentRow["Transaction_DBID"]} will be
            canceled. All associated data will be reverted.
          </Modal.Body>
          <Modal.Footer>
            <div className="confirm">
              <Button
                variant="outline-primary"
                onClick={() => closeModal(true)}
                className="footer-button"
              >
                Cancel
              </Button>
              <Button
                className="ms-2 footer-button"
                variant="danger"
                onClick={() => {
                  deleteRow();
                }}
              >
                Confirm
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}
      <Menu
        id="basic-menu"
        anchorEl={actionContextAnchor}
        open={actionContextAnchor !== null}
        onClose={() => setActionContextAnchor(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => openHistory()}>
          <ChatLeft className="context-icon" onClick={openHistory} />
          History
        </MenuItem>
        <MenuItem onClick={() => openDetails()}>
          <Grid3x3 className="context-icon" onClick={openDetails} />
          Details
        </MenuItem>
        {JSON.parse(localStorage.getItem("user")).oid ===
        currentRow?.CreatedOID ? (
          <span>
            <Divider />
            <MenuItem onClick={() => openDelete()}>
              <Trash color="red" className="context-icon" />
              Cancel
            </MenuItem>
          </span>
        ) : null}
      </Menu>
    </div>
  );
}

export default TransactionsList;
