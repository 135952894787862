import React, { Component } from "react";
import { Api } from "./interface";
import HistoryList from "./historyList";
import { formatHistoryResponse } from "./common";
class HistoryView extends Component {
  constructor(props) {
    super(props);

    this.tableCellHover = [
      {
        "&:hover": {
          backgroundColor: "#BDBDBD",
        },
      },
    ];

    this.textInput = React.createRef();
    this.commentInput = React.createRef();
    this.historyRef = React.createRef();

    this.state = {
      entities: [],
      parentEntityList: [],
      items: [],
      filteredItems: [],
      entityTypes: [],
      template: [],
      filteredTemplate: [],
      statuses: [],
      metaOptions: [],
      fieldArray: [],
      currentEntity: {},
      currentEntityId: -1,
      isOpen: false,
      editing: false,
      cloning: false,
      renaming: false,
      deleting: false,
      statusEdit: -1,
      commenting: false,
      showHistory: true,
      comment: "",
      currentGroupChildren: [],
      currentHistory: [],
      filteredHistory: [],
      currentFilterText: "",
      filterTerm: "",
      filteredRows: [],
      rawTemplate: [],
    };
  }

  componentDidMount() {
    this.getHistory();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getHistory();
    }
  }

  getHistory = () => {
    Api({
      sp: "getEntityMetaHistory",
      json: { id: this.props.item.Entity_DBID },
    }).then((response) => {
      response = formatHistoryResponse(response);

      this.setState({
        currentHistory: response,
        filteredHistory: response,
        currentEntity: this.props.item,
        commenting: true,
      });
      this.historyScrollToBottom("auto");
    });
  };

  historyScrollToBottom = (behavior) => {
    setTimeout(() => {
      this.historyRef.current.scrollIntoView({ behavior: behavior });
    }, 0);
  };

  render = () => {
    return (
      <>
        <div className="text-center p-2">
          <h4>History</h4>
          <hr />
        </div>
        <div className="field-view">
          <div className="field-view">
            <HistoryList items={this.state.filteredHistory} value="Equipment" />
            <div ref={this.historyRef}></div>
          </div>
        </div>
      </>
    );
  };
}

export default HistoryView;
