import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { Api } from "./interface";
import { Autocomplete, TextField } from "@mui/material";

export default function EntityStatus(props) {
  const [steps, setSteps] = useState([]);
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Api({
      sp: "getNextWorkflowStep",
      json: {
        workflow: "Status",
        step: props.items[0].EntityStatus[0],
        level: props.level,
      },
    }).then((response) => {
      setSteps(response);
    });
  };

  const updateStatus = () => {
    let ids = props.items.map((x) => x.Entity_DBID);
    Api({
      sp: "updateEntityStatus",
      json: { entityIds: ids, status: status.Target, notes: notes },
    }).then(() => {
      props.close(true);
    });
  };

  return (
    <Modal
      show={props.open}
      onHide={() => props.close()}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>
          Update {props.items.length > 1 ? "Items" : props.items[0].EntityName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-box">
          <Autocomplete
            size="sm"
            autoHighlight={true}
            id="filter-options"
            className="mt-2"
            options={steps}
            getOptionLabel={(option) => option.Target}
            onChange={(event, newValue) => setStatus(newValue)}
            renderTags={() => null}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField autoFocus {...params} label="New Status" />
            )}
          />
          <Form.Control
            placeholder="Notes"
            className="mb-2 mt-2"
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={() => props.close()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={(e) => {
            e.currentTarget.disabled = true;
            updateStatus();
          }}
          disabled={!status || notes.length < 5}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
