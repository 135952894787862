// JavaScript source code
import React, { Component } from "react";
import readXlsxFile from "read-excel-file";
import Modal from "react-bootstrap/Modal";
import { Api } from "./interface";
import TextField from "@mui/material/TextField";
import { Autocomplete, Chip } from "@mui/material";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { validateData } from "./validator"; //IsDuplicate
import { groupSelectError, isJson } from "./common";
//import { Download } from "react-bootstrap-icons";
import * as FileSaver from "file-saver";
//import * as XLSX from "xlsx";  // TODO: remove package if not needed
import { getDateTime } from "./date";
import { Hint } from "./constants.js";
import { InfoCircle } from "react-bootstrap-icons";
import Excel from "exceljs";
// import { Cookies } from "react-cookie";
import Form from "react-bootstrap/Form";

class EntityBulkUpload extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    // const cookies = new Cookies();
    this.comment = React.createRef();

    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      show: this.props.createBulk,
      rows: this.props.rows,
      savedData: this.props.savedData,
      giveIts: this.props.giveIts,
      duplicates: this.props.duplicates,
      entityTypes: [],
      entityType: null,
      parentEntityList: [],
      entityParent: null,
      entityOwner: null,
      errorLog: false,
      showResult: false,
      file: "",
      dbColumns: [],
      spinner: this.props.spinner,
      newEntityTypes: [],
      metaTypes: [],
      selectedMetaType: [],
      newMetaTypeId: -1,
      users: [],
      statusList: [{ Name: "In Inventory" }, { Name: "In Transit" }],
      entityStatus: "In Inventory",
      giveComment: "",
    };

    this.inValidMeta = [];
    this.templateError = [];
  }
  componentDidMount() {
    Api({
      sp: "getEntityTypes",
      json: {},
    }).then((types) => {
      if (this.props.level !== "Group") {
        types = types.filter((x) => {
          return this.props.level.length
            ? x.Level === this.props.level
            : ![
                "Material",
                "Location",
                "Lab",
                "Site",
                "Geo",
                "Building",
                "Floor",
              ].includes(x.Level);
        });
      }

      if (
        this.props.type ||
        ["Geo", "Lab", "Building", "Floor"].includes(this.props.level)
      ) {
        let newEntiyType = null;

        if (types.length === 1) {
          newEntiyType = types[0];
        } else {
          newEntiyType = types.find(
            (type) => type.EntityType === this.props.type
          );
        }
        this.setState({
          entityTypes: types,
          entityType: newEntiyType,
        });

        this.updateParentList(newEntiyType);
      } else {
        this.setState({
          entityTypes: types,
          entityType: null,
        });
      }
    });

    Api({ sp: "getMetaGroups", json: {} }).then((response) => {
      this.setState({
        metaTypes: response,
      });
    });

    Api({
      sp: "getUsers",
      json: {},
    }).then((users) => {
      this.setState({
        users: users,
      });

      if (this.state.user) {
        let OID = this.state.user.oid;
        let user = users.find((user) => user.OID === OID);
        this.setState({ entityOwner: user });
      }
    });

    if (this.props.duplicates.length > 0) {
      this.setState({
        duplicates: this.props.duplicates,
        errorLog: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.savedData.length > 0 || this.props.duplicates.length > 0) {
        this.setState({
          savedData: this.props.savedData,
          show: false,
          showResult: true,
          duplicates: this.props.duplicates,
        });

        if (this.props.duplicates.length > 0) {
          this.setState({
            duplicates: this.props.duplicates,
            errorLog: true,
          });
        }

        if (this.props.giveIts) {
          this.setState({
            giveIts: this.props.giveIts,
          });
        }
      }

      if (this.state.spinner !== this.props.spinner) {
        this.setState({ spinner: this.props.spinner });
      }
    }
  }

  validateLocation = (obj, i) => {
    let locations = this.state.parentEntityList;
    if (obj["Location"] !== undefined) {
      let location = obj["Location"];
      let parent = locations.find((loc) => {
        return loc.Path.toLowerCase() === location.toLowerCase();
      });
      if (parent) {
        obj["Location"] = parent.Entity_DBID; // add Path
      } else {
        //invalid location error
        this.inValidMeta.push(
          "Row Num : " + (i + 1) + " - Location is Invalid."
        );
      }
    } else {
      obj["Location"] = this.state.entityParent?.Entity_DBID; // add Path
    }
    return obj;
  };

  validateOwner = (obj, i) => {
    let users = this.state.users;
    if (obj.EntityOwner) {
      let owner = JSON.parse(obj.EntityOwner)[0];
      if (owner) {
        let user = users.find((user) => {
          return user.UserName.toLowerCase() === owner.toLowerCase();
        });
        if (user) {
          obj["EntityOwner"] = JSON.stringify([user.User_DBID.toString()]);
          obj["EntityOwnerName"] = JSON.stringify([user.UserName]);
        } else {
          //invalid owner error
          this.inValidMeta.push(
            "Row Num : " + (i + 1) + " - Owner is Invalid."
          );
        }
      }
    } else {
      if (this.state.entityOwner) {
        obj["EntityOwner"] = this.state.entityOwner
          ? JSON.stringify([this.state.entityOwner?.User_DBID.toString()])
          : null;
        obj["EntityOwnerName"] = this.state.entityOwner
          ? JSON.stringify([this.state.entityOwner?.UserName])
          : null;
      } else {
        let OID = this.state.user.oid;

        let user = this.state.users.find((user) => user.OID === OID);

        obj["EntityOwner"] = JSON.stringify([user?.User_DBID.toString()]);
        obj["EntityOwnerName"] = JSON.stringify([user?.UserName]);
      }
    }

    return obj;
  };

  onUpload = () => {
    if (!this.state.file.includes("xlsx")) {
      toast.error("Only xlsx files are supported");
    } else {
      readXlsxFile(this.input.current.files[0]).then((rows) => {
        if (rows.length < 2) {
          toast.error("Uploaded file does not have data");
        } else {
          this.setState({ spinner: true });

          if (this.props.level === "Group") {
            this.getGroupsTemplate(
              this.state.newEntityTypes,
              this.state.selectedMetaType,
              false,
              rows
            );
          } else {
            Api({
              sp: "getTemplateByType",
              json: { type: this.state.entityType.EntityType },
            }).then((dbColumns) => {
              if (this.props.level !== "Material") {
                dbColumns.push({ EntityMetaType: "Name" });
              }
              // if (
              //   this.props.level === "Material" &&
              //   this.state.entityType.EntityType !== "SSD"
              // ) {
              //   dbColumns.unshift({
              //     EntityMetaType: "TotalQuantity",
              //     DataType: "Integer",
              //   });
              //   dbColumns.unshift({
              //     EntityMetaType: "AvailableQuantity",
              //     DataType: "Integer",
              //   });
              // }
              if (this.props.level !== "Group") {
                dbColumns.unshift({ EntityMetaType: "Location" });
                dbColumns.unshift({
                  EntityMetaType: "EntityOwner",
                  DataType: "String",
                });
              }
              this.setState({ dbColumns: dbColumns });

              this.templateError = this.checkTemplate(dbColumns, rows);
              if (this.templateError.length > 0) {
                this.inValidMeta.push(
                  this.tempError(
                    this.state.entityType.EntityType,
                    this.templateError
                  )
                );
              }

              // check for Hint row here
              let hint = this.createHintRow(
                this.state.entityType.EntityType,
                dbColumns,
                true
              );
              rows = this.removeHintRow(rows, hint);
              Api({
                sp: "getMetaOptionsByType",
                json: { type: this.state.entityType.EntityType },
              }).then((response) => {
                this.processUpload(dbColumns, response, rows);
              });
            });
          }
        }
      });
    }
  };

  processUpload = (dbColumns, response, rows) => {
    let fileName = this.input.current.files[0]?.name;

    let fileColumns,
      finalJSON = [],
      keys = [];
    dbColumns.forEach((c) => {
      if (c.DataType) {
        keys.push(c.EntityMetaType);
      }
      if (c.DataType === "Group" || c.DataType === "Multiple Choice") {
        c.options = [];

        response.forEach((y) => {
          if (c.EntityMetaType === y.EntityMetaType) {
            c.options.push(y.EntityMetaOption);
          }
        });
      }
    });
    rows.forEach((row, i) => {
      if (i === 0) {
        fileColumns = row;
      } else {
        let obj = {};
        if (row[i] && typeof row[i] === "string") {
          row[i] = row[i].trim();
        }

        if (this.props.level !== "Material") {
          if (row[0] === "" || row[0] === null) {
            this.inValidMeta.push(
              "Row Num : " + (i + 1) + " - Name is missing for the entry"
            );
          }
        }

        fileColumns.forEach((c, index) => {
          let col = dbColumns.find(
            (dbCol) => dbCol.EntityMetaType.toLowerCase() === c.toLowerCase()
          );

          if (row[index] === null || row[index] === undefined) {
            return; // not adding null or undefined values
          }

          if (col) {
            obj[col.EntityMetaType] = [];

            if (
              (col.DataType === "Group" ||
                col.DataType === "Multiple Choice") &&
              col.AllowMultiple === 0
            ) {
              let temp = col.options.find((opt) => {
                return (
                  opt.toString().toLowerCase() ===
                  row[index].toString().toLowerCase()
                );
              });
              if (temp) {
                row[index] = temp;
              } else {
                this.inValidMeta.push(
                  groupSelectError(i, row[index], col.EntityMetaType)
                );
              }
            } else if (
              (col.DataType === "Group" ||
                col.DataType === "Multiple Choice") &&
              col.AllowMultiple === 1
            ) {
              let array = row[index].toString().split("|"); // Getting individual entry of multi values
              let values = [];
              array.forEach((ary) => {
                let temp = col.options.find((opt) => {
                  return (
                    opt.toString().toLowerCase() ===
                    ary.toString().toLowerCase()
                  );
                });
                if (temp) {
                  values.push(temp);
                } else {
                  this.inValidMeta.push(
                    groupSelectError(i, ary, col.EntityMetaType)
                  );
                }
                row[index] = values.join("|");
              });
            } else if (
              col.DataType !== "Group" &&
              col.DataType !== "Multiple Choice" &&
              col.AllowMultiple === 1
            ) {
              let array = row[index].toString().split("|");

              array.forEach((ary) => {
                let resultArray = validateData(col, ary, i); // Multi string, int, date, phone etc.

                if (resultArray.length > 0) {
                  this.inValidMeta.push(...resultArray);
                }
              });
            } else {
              let resultArray = validateData(col, row[index], i); // single string, int, date, floar etc. type
              if (resultArray.length > 0) {
                this.inValidMeta.push(...resultArray);
              }
            }

            if (col.DataType === "Binary") {
              row[index] = row[index] === 0 ? "" : row[index];
            }
            if (col.DataType === "Date") {
              if (col.AllowMultiple === 0) {
                let date = new Date(row[index]);
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                row[index] = date;
              }
            }

            obj[col.EntityMetaType] =
              col.AllowMultiple === 1
                ? JSON.stringify([...row[index].toString().split("|")])
                : col.DataType
                ? JSON.stringify([row[index].toString()])
                : row[index];
          }
        });

        // Location Validation Check
        if (this.props.level !== "Group") {
          obj = this.validateLocation(obj, i);
          obj = this.validateOwner(obj, i);
        }
        // check for qty validation for material type
        // if (
        //   this.props.level === "Material" &&
        //   this.state.entityType.EntityType !== "SSD"
        // ) {
        //   if (!obj.AvailableQuantity || !obj.TotalQuantity) {
        //     this.inValidMeta.push(
        //       "Row Num : " + (i + 1) + " - Available/Total Quantity is missing"
        //     );
        //   } else {
        //     let qty = parseInt(JSON.parse(obj.AvailableQuantity)[0]);
        //     let totalQty = parseInt(JSON.parse(obj.TotalQuantity)[0]);

        //     if (totalQty < qty) {
        //       this.inValidMeta.push(this.qtyError(i, qty, totalQty));
        //     }
        //   }
        // }

        if (this.inValidMeta.length === 0) {
          obj.rowIndex = i - 1;
          finalJSON.push(obj);
        }
      }
    });
    let ary = [];
    for (let i = 0; i < finalJSON.length; i++) {
      ary.push(`'$.finalData[${i}].UUID',UUID()`);
    }
    let query = ary.join(",");

    if (this.inValidMeta.length > 0) {
      this.setState({
        show: false,
        errorLog: true,
        spinner: false,
      });
    } else {
      if (this.props.level === "Group") {
        this.props.saveData(
          finalJSON,
          keys,
          this.state.selectedMetaType,
          this.state.newEntityTypes
        );
      } else {
        keys.push("EntityOwnerName");
        this.props.saveData(
          finalJSON,
          keys,
          query,
          this.state.entityParent.Entity_DBID,
          this.state.entityType.EntityType_DBID,
          fileName,
          this.state.parentEntityList,
          this.state.entityType.EntityType,
          this.state.entityStatus,
          this.state.giveComment
        );
      }
    }
  };

  render = () => {
    return (
      <>
        <Modal
          onEntered={() => this.input.current.focus()}
          show={this.state.show || this.state.errorLog || this.state.showResult}
          onHide={() => this.closeModal()}
          className={
            this.state.errorLog || this.state.showResult ? "upload-report" : ""
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.level.length
                ? this.props.type
                  ? this.props.type + "s"
                  : this.props.level + "s"
                : "Equipments"}{" "}
              Upload{" "}
              {this.state.errorLog
                ? "Error Report"
                : this.state.showResult
                ? " Successful!"
                : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={this.state.show ? "display" : "hide"}>
              <div
                className={
                  this.props.type ||
                  ["Geo", "Lab", "Building", "Floor", "Group"].includes(
                    this.props.level
                  )
                    ? "hide"
                    : "display mb-2"
                }
              >
                New {this.props.level.length ? this.props.level : "Equipment"}{" "}
                Type
                <div>
                  <Autocomplete
                    autoHighlight={true}
                    className="type-ahead"
                    id="entity-type"
                    value={this.state.entityType}
                    options={this.state.entityTypes}
                    getOptionLabel={(option) => option.EntityType}
                    hiddenlabel="true"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Type"
                      />
                    )}
                    onChange={(_event, newType) => {
                      this.updateType(newType);
                    }}
                    disabled={this.state.spinner || this.props.type}
                  />
                </div>
              </div>

              <div
                className={
                  this.props.level !== "Group" ? "display mb-2" : "hide"
                }
              >
                Default Location
                <Hint
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  title={
                    "Default location will be used if no location is provided in the Excel template"
                  }
                >
                  <sup className="infoIcon">
                    <InfoCircle color="grey" size={12} />
                  </sup>
                </Hint>
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="parent-entity"
                  options={this.state.parentEntityList}
                  getOptionLabel={(option) => option.Path}
                  hiddenlabel="true"
                  value={this.state.entityType ? this.state.entityParent : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Location"
                    />
                  )}
                  disabled={!this.state.entityType || this.state.spinner}
                  onChange={(_event, newParent) => {
                    this.setState({ entityParent: newParent });
                  }}
                />
              </div>
              <div
                className={
                  this.props.level !== "Group" ? "display mb-2" : "hide"
                }
              >
                Default Owner
                <Hint
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  title={
                    "Default Owner will be used if no owner is provided in the Excel template"
                  }
                >
                  <sup className="infoIcon">
                    <InfoCircle color="grey" size={12} />
                  </sup>
                </Hint>
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="owner-entity"
                  options={this.state.users}
                  getOptionLabel={(option) => option.UserName}
                  hiddenlabel="true"
                  value={this.state.entityOwner}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Owner"
                    />
                  )}
                  disabled={this.state.spinner}
                  onChange={(_event, newOwner) => {
                    this.setState({ entityOwner: newOwner });
                  }}
                />
              </div>
              <div
                className={
                  this.props.level !== "Group" &&
                  this.props.level === "Material" &&
                  this.state.entityType &&
                  ["Component", "SSD"].includes(
                    this.state.entityType.EntityType
                  )
                    ? "display mb-2"
                    : "hide"
                }
              >
                Status
                <Hint
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  title={
                    "In Inventory will be default status if nothing selected"
                  }
                >
                  <sup className="infoIcon">
                    <InfoCircle color="grey" size={12} />
                  </sup>
                </Hint>
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="status-entity"
                  options={this.state.statusList}
                  getOptionLabel={(option) => option.Name}
                  hiddenlabel="true"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Status"
                    />
                  )}
                  disabled={this.state.spinner}
                  onChange={(_event, option) => {
                    this.setState({ entityStatus: option.Name });
                  }}
                />
              </div>
              <div
                className={
                  this.props.level !== "Group" &&
                  this.props.level === "Material" &&
                  this.state.entityType &&
                  this.state.entityType.EntityType === "SSD" &&
                  this.state.entityStatus === "In Transit"
                    ? "display mb-2"
                    : "hide"
                }
              >
                Comment
                <Hint
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  title={"Comment for Give It"}
                >
                  <sup className="infoIcon">
                    <InfoCircle color="grey" size={12} />
                  </sup>
                </Hint>
                <Form.Control
                  ref={this.comment}
                  type="text"
                  placeholder="Enter Comment"
                  onChange={(e) =>
                    this.setState({ giveComment: e.target.value.trim() })
                  }
                />
              </div>
              <div
                className={
                  this.props.level === "Group" ? "display mb-2" : "hide"
                }
              >
                Select Type:
                <Autocomplete
                  autoHighlight={true}
                  size="small"
                  multiple
                  filterSelectedOptions
                  className="type-ahead"
                  limitTags={3}
                  hiddenlabel="true"
                  id="field-filter"
                  onChange={(event, newValue) => {
                    this.handleTypeChange(newValue);
                  }}
                  options={this.state.entityTypes}
                  isOptionEqualToValue={(option, value) =>
                    option.EntityType_DBID === value.EntityType_DBID
                  }
                  getOptionLabel={(option) => option.EntityType}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        key={index}
                        label={option.EntityType}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      style={{ width: "100%" }}
                      placeholder="Template Type"
                    />
                  )}
                />
              </div>
              <div
                className={
                  this.props.level === "Group" ? "display mb-2" : "hide"
                }
              >
                Select Field:
                <div className="mb-2">
                  <Autocomplete
                    autoHighlight={true}
                    className="type-ahead"
                    id="meta-type"
                    options={this.state.metaTypes}
                    getOptionLabel={(option) => option.EntityMetaType}
                    hiddenlabel="true"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select/Type"
                      />
                    )}
                    onChange={(_event, selected) => {
                      console.log("selected" + JSON.stringify(selected));
                      this.setState({
                        newMetaTypeId: selected
                          ? selected.EntityMetaType_DBID
                          : -1,
                        selectedMetaType: selected,
                      });
                    }}
                  />
                </div>
              </div>

              <input
                type="file"
                id="input"
                ref={this.input}
                onChange={() =>
                  this.setState({ file: this.input.current.value })
                }
                disabled={this.state.spinner}
              />
            </div>

            <div className={this.state.errorLog ? "display" : "hide"}>
              <table>
                {this.inValidMeta.map((meta, i) => {
                  return (
                    <tr key={"inMeta" + i}>
                      <td>{meta}</td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <div className={this.state.showResult ? "display" : "hide"}>
              {/*Duplicate records*/}
              <table
                className={
                  this.state.duplicates?.length > 0 ? "display" : "hide"
                }
              >
                <tr>
                  <td>
                    {" "}
                    <b>Unique Constraint Violation</b>
                  </td>
                </tr>

                {this.state.duplicates?.map((ent, i) => {
                  return (
                    <tr key={"dupEtName" + i}>
                      <td>{this.uniqueConstraint(ent)}</td>
                    </tr>
                  );
                })}
              </table>

              <table>
                <tr>
                  <td colSpan="2">
                    <b>Total = {this.state.savedData?.length} </b>
                    <br />
                    <b>List of Uploaded Entities:</b>
                  </td>
                </tr>
                {this.state.giveIts && (
                  <tr>
                    <td>Give Id(s)</td>
                    <td>{this.state.entityType.EntityType} List</td>
                  </tr>
                )}
                {this.state.entityStatus === "In Inventory" &&
                  this.state.savedData?.map((ent, i) => {
                    const name =
                      this.props.level === "Material"
                        ? "MAT" + String(ent.Entity_DBID).padStart(8, 0)
                        : ent.EntityName;

                    return (
                      <tr key={"etName" + i}>
                        <td>{name}</td>
                      </tr>
                    );
                  })}

                {this.state.entityStatus === "In Transit" && this.state.giveIts
                  ? Object.keys(this.state.giveIts).map((key, i) => {
                      return (
                        <tr key={"key" + i}>
                          <td className="giveId">
                            {String(key).padStart(8, 0)}
                          </td>
                          {"    "}
                          <td>
                            {" "}
                            {this.state.giveIts[key]
                              .map((ent) => String(ent).padStart(8, 0))
                              .join(", ")}{" "}
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className={this.state.show ? "display" : "hide"}>
              {this.state.spinner ? (
                <div className="spinner-border spinner" role="status"></div>
              ) : (
                ""
              )}

              <div className={"template-download"}>
                <Hint
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  title={"Download Template"}
                >
                  <span>
                    <Button
                      className="icon-btn"
                      type="radio"
                      variant="outline-primary"
                      onClick={() => this.templateDownload()}
                      disabled={this.shouldDownload()}
                    >
                      Download Template{" "}
                      {/*<Download className="export" size={15} />*/}
                    </Button>
                  </span>
                </Hint>
              </div>

              <div className="upload-btns">
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.currentTarget.disabled = true;
                    this.onUpload();
                  }}
                  disabled={this.saveButtonDisabled()}
                >
                  Save
                </Button>
              </div>
            </div>
            <div
              className={
                this.state.errorLog || this.state.showResult
                  ? "display"
                  : "hide"
              }
            >
              {this.state.errorLog ? (
                <div className="error-btn-grp1">
                  <Button
                    variant="outline-primary"
                    onClick={() => this.goBack()}
                  >
                    Go Back
                  </Button>{" "}
                </div>
              ) : (
                ""
              )}

              <div className="error-btn-grp2">
                {this.state.errorLog ? (
                  <Button
                    variant="outline-primary"
                    onClick={() => this.download(this.inValidMeta)}
                  >
                    Download
                  </Button>
                ) : (
                  <Button
                    variant="outline-primary"
                    onClick={() => this.download(this.props.duplicates, true)}
                  >
                    Download
                  </Button>
                )}

                <Button
                  className="ms-2"
                  variant="outline-primary"
                  onClick={() => this.closeModal()}
                >
                  Close
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  checkTemplate = (dbColumns, rows) => {
    let columns = rows[0];

    let list = [];
    columns.forEach((col) => {
      let object = dbColumns.find(
        (cl) => cl["EntityMetaType"].toLowerCase() === col.toLowerCase()
      );
      if (object === undefined) {
        list.push(col);
      } else if (object.DataType === "Image") {
        // if column has image type we want to show error
        list.push(col + " (image type)");
      }
    });

    return list;
  };

  download = (inValidMeta, uploadReport = false) => {
    let fileData = "";
    let names = [];
    if (uploadReport) {
      let ary = [];
      if (inValidMeta.length > 0) {
        inValidMeta.forEach((ent) => {
          ary.push(this.uniqueConstraint(ent));
        });
        fileData = "Unique Constraint Violation \n".concat(ary.join("\n"));
      }
      let uploaded = this.state.savedData;
      if (this.state.giveIts) {
        Object.keys(this.state.giveIts).map((key) => {
          names.push(
            String(key).padStart(8, 0) +
              "  -  " +
              this.state.giveIts[key]
                .map((s) => String(s).padStart(8, 0))
                .join(",") +
              " \n"
          );
        });

        fileData = fileData.concat("\n \nList of Uploaded Entities \n");
        let header =
          "\n \nGive Id(s) ------ " +
          this.state.entityType.EntityType +
          " List -----------------------------\n";

        fileData = fileData.concat(header);
        fileData = fileData.concat(names.join("\n"));
        fileData = fileData.concat("\nTotal: " + uploaded.length);
      } else {
        names =
          this.props.level === "Material"
            ? uploaded.map(
                (ent) => "MAT" + String(ent.Entity_DBID).padStart(8, 0)
              )
            : uploaded.map((ent) => ent.EntityName);
        fileData = fileData.concat("\n \nList of Uploaded Entities \n");
        fileData = fileData.concat(names.join("\n"));
        fileData = fileData.concat("\nTotal: " + uploaded.length);
      }
    } else {
      fileData = inValidMeta.join("\n");
    }

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "upload_report_" + getDateTime() + ".txt";
    link.href = url;
    link.click();
  };

  parseJsonData = (data) => {
    return Array.isArray(JSON.parse(data))
      ? JSON.parse(data).join(",")
      : JSON.parse(data);
  };

  uniqueConstraint = (obj) => {
    if (this.props.level === "Group") {
      if (obj.IDs) {
        let ids = obj.IDs.split(",");

        return `Rows : ${ids.map(
          (v) => parseInt(v) + 2
        )} have duplicate value ${obj.Val1} for ${obj.Meta1} `;
      } else {
        return `Row Num: ${obj.Row_Id + 2} - ${obj.Meta1}'s value ${
          obj.Val1
        } is not unique`;
      }
    } else {
      if (obj.IDs) {
        let ids = obj.IDs.split(",");

        if (obj.Meta2) {
          if (obj.Meta2 === "Location") {
            return `Rows : ${ids.map(
              (v) => parseInt(v) + 2
            )} have duplicate Name ${obj.Val1} for the same Location`;
          } else {
            return `Rows : ${ids.map(
              (v) => parseInt(v) + 2
            )} have duplicate value ${
              isJson(obj.Val1) ? this.parseJsonData(obj.Val1) : obj.Val1
            } and ${
              isJson(obj.Val2) ? this.parseJsonData(obj.Val2) : obj.Val2
            }for ${obj.Meta1} and ${obj.Meta2}`;
          }
        } else {
          return `Rows : ${ids.map(
            (v) => parseInt(v) + 2
          )} have duplicate value ${this.parseJsonData(obj.Val1)} for ${
            obj.Meta1
          }`;
        }
      } else {
        if (obj.Meta2) {
          return `Row Num: ${obj.Row_Id + 2} - ${obj.Meta1}'s value ${
            isJson(obj.Val1) ? this.parseJsonData(obj.Val1) : obj.Val1
          } is not unique for ${obj.Meta2}'s value ${
            isJson(obj.Val2) ? this.parseJsonData(obj.Val2) : obj.Val2
          }`;
        } else {
          return `Row Num: ${obj.Row_Id + 2} - ${
            obj.Meta1
          }'s value ${this.parseJsonData(obj.Val1)} is not unique`;
        }
      }
    }
  };
  shouldDownload = () => {
    if (this.props.level === "Group") {
      return (
        this.state.spinner ||
        this.state.newEntityTypes.length === 0 ||
        this.state.newMetaTypeId === -1
      );
    } else {
      return (
        this.state.spinner ||
        (this.state.entityType && this.state.entityType.EntityType
          ? false
          : true)
      );
    }
  };
  templateDownload = () => {
    if (this.props.level === "Group") {
      this.getGroupsTemplate(
        this.state.newEntityTypes,
        this.state.selectedMetaType,
        true,
        []
      );
    } else {
      this.getTemplate(this.state.entityType.EntityType);
    }
  };

  getGroupsTemplate = (newEntityTypes, selectedMetaType, isDownload, rows) => {
    Api({
      sp: "getGroupTemplateByField",
      json: {
        typeIds: JSON.stringify(
          newEntityTypes.map((type) => type.EntityType_DBID)
        ),
        fieldId: selectedMetaType.EntityMetaType_DBID,
      },
    }).then((dbColumns) => {
      // filter down the template fields by removing duplicates
      dbColumns = dbColumns.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.EntityMetaType_DBID === v.EntityMetaType_DBID
          ) === i
      );

      Api({
        sp: "getMetaOptionsByFieldAndType",
        json: {
          typeIds: JSON.stringify(
            newEntityTypes.map((type) => type.EntityType_DBID)
          ),
        },
      }).then((response) => {
        if (isDownload) {
          this.downloadTemplate(
            selectedMetaType.EntityMetaType,
            dbColumns,
            response,
            false
          );
        } else {
          dbColumns.push({ EntityMetaType: "Name" });
          this.templateError = this.checkTemplate(dbColumns, rows);
          if (this.templateError.length > 0) {
            this.inValidMeta.push(
              this.tempError(
                selectedMetaType.EntityMetaType,
                this.templateError
              )
            );
          }
          let hint = this.createHintRow("Group", dbColumns, false);
          rows = this.removeHintRow(rows, hint);
          this.processUpload(dbColumns, response, rows);
        }
      });
    });
  };

  getTemplate = (entType) => {
    Api({
      sp: "getTemplateByType",
      json: { type: entType },
    }).then((dbColumns) => {
      Api({
        sp: "getMetaOptionsByType",
        json: { type: this.state.entityType.EntityType },
      }).then((response) => {
        this.downloadTemplate(entType, dbColumns, response, true);
      });
    });
  };

  downloadTemplate = (entType, dbColumns, response, isEntity) => {
    const workbook = new Excel.Workbook(); // Create a new workbook
    const worksheet = workbook.addWorksheet("data");
    const worksheet2 = workbook.addWorksheet("validation");
    let colList = [];
    let obj = {};
    if (this.props.level !== "Material") {
      colList.push({ header: "Name", key: "Name" });
      obj["Name"] = "Entity Name";
    }

    if (isEntity) {
      colList.push({ header: "Location", key: "Location" });
      obj["Location"] = "Select Location";
      let options = [];
      this.state.parentEntityList.map((location) => {
        options.push(location.Path);
      });
      this.validationList(
        Object.keys(obj).length,
        worksheet,
        worksheet2,
        options,
        "Location"
      );

      colList.push({ header: "EntityOwner", key: "EntityOwner" });
      obj["EntityOwner"] = "Select Owner";
      options = [];
      this.state.users.map((user) => {
        options.push(user.UserName);
      });
      this.validationList(
        Object.keys(obj).length,
        worksheet,
        worksheet2,
        options,
        "EntityOwner"
      );

      // if (this.props.level === "Material" && entType !== "SSD") {
      //   colList.push({
      //     header: "AvailableQuantity",
      //     key: "AvailableQuantity",
      //   });
      //   colList.push({
      //     header: "TotalQuantity",
      //     key: "TotalQuantity",
      //   });
      //   obj["AvailableQuantity"] = "10";
      //   obj["TotalQuantity"] = "20";
      // }
    }

    dbColumns.forEach((col) => {
      let key = col.EntityMetaType;

      if (col.DataType !== "Image") {
        colList.push({ header: key, key: key });

        if (col.DataType === "String") {
          obj[key] = "Text...";
        } else if (col.DataType === "Integer") {
          obj[key] = "12";
        } else if (col.DataType === "Float") {
          obj[key] = "1.2";
        } else if (
          col.DataType === "Group" ||
          col.DataType === "Multiple Choice"
        ) {
          obj[key] = "Select Option";
          let options = [];
          response.forEach((y) => {
            if (key === y.EntityMetaType) {
              options.push(y.EntityMetaOption);
            }
          });
          this.validationList(
            colList.length,
            worksheet,
            worksheet2,
            options,
            key
          );
        } else if (col.DataType === "Binary") {
          obj[key] = "0(or 1)";
        } else if (col.DataType === "URL") {
          obj[key] = "www.myurl.com";
        } else if (col.DataType === "Email") {
          obj[key] = "name@intel.com";
        } else if (col.DataType === "Date") {
          obj[key] = "12/30/2019 (mm/dd/yyyy)";
        } else if (col.DataType === "Phone Number") {
          obj[key] = "123-123-1234";
        } else if (col.DataType === "User Lookup") {
          obj[key] = "UserName";
        }
        if (col.AllowMultiple) {
          obj[key] = obj[key] + "|" + obj[key];
        }
      }
    });

    worksheet.columns = colList;
    worksheet.addRow(obj);
    worksheet2.columns = colList;

    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, entType + "_Format_" + getDateTime() + ".xlsx");
    });
  };

  createHintRow = (entType, dbColumns, isEntity) => {
    let obj = {};
    let hint = [];
    if (this.props.level !== "Material") {
      hint.push("Entity Name");
    }

    if (isEntity) {
      hint.push("Select Location");
      hint.push("Select Owner");
      if (this.props.level === "Material" && entType !== "SSD") {
        hint.push("10");
        hint.push("20");
      }
    }
    dbColumns.forEach((col) => {
      let key = col.EntityMetaType;
      if (col.DataType !== "Image") {
        if (col.DataType === "String" && col.EntityMetaType !== "EntityOwner") {
          obj[key] = "Text...";
        } else if (col.DataType === "Integer") {
          obj[key] = "12";
        } else if (col.DataType === "Float") {
          obj[key] = "1.2";
        } else if (
          col.DataType === "Group" ||
          col.DataType === "Multiple Choice"
        ) {
          obj[key] = "Select Option";
        } else if (col.DataType === "Binary") {
          obj[key] = "0(or 1)";
        } else if (col.DataType === "URL") {
          obj[key] = "www.myurl.com";
        } else if (col.DataType === "Email") {
          obj[key] = "name@intel.com";
        } else if (col.DataType === "Date") {
          obj[key] = "12/30/2019 (mm/dd/yyyy)";
        } else if (col.DataType === "Phone Number") {
          obj[key] = "123-123-1234";
        } else if (col.DataType === "User Lookup") {
          obj[key] = "UserName";
        }
        if (col.AllowMultiple) {
          obj[key] = obj[key] + "|" + obj[key];
        }
        if (obj[key]) {
          hint.push(obj[key]);
        }
      }
    });
    return hint;
  };

  removeHintRow = (rows, hint) => {
    let row = rows[1];
    let flag = false;
    row.forEach((r) => {
      if (hint.includes(r)) {
        let ind = hint.indexOf(r);
        hint.splice(ind, 1);
      } else {
        flag = true;
      }
    });
    if (!flag) {
      rows.splice(1, 1);
      return rows;
    } else {
      return rows;
    }
  };

  getTemplateGroup = () => {};
  validationList = (position, sheet1, sheet2, options, key) => {
    let letter = sheet1.getColumn(position).letter;

    //let joinedlist = '"' + options.join(",") + '"';
    let address = `${letter}2:${letter}1048576`;
    let range = `validation!$${letter}$2:$${letter}$${options.length + 1}`;
    sheet1.dataValidations.add(address, {
      type: "list",
      allowBlank: true,
      formulae: [range],
      showErrorMessage: true,
      errorStyle: "error",
      error:
        "The value does not match the data validation restrictions defined for this cell",
    });

    sheet2.getColumn(position).values = [key, ...options];
  };

  goBack = () => {
    this.input.current.value = "";

    this.setState({ file: "", show: true, errorLog: false });
    this.inValidMeta = [];
    this.templateError = [];
  };

  saveButtonDisabled = () => {
    if (this.props.level === "Group") {
      return (
        this.state.file === "" ||
        this.state.spinner ||
        this.state.newEntityTypes.length === 0 ||
        this.state.newMetaTypeId === -1
      );
    } else {
      return (
        this.state.entityType === null ||
        this.state.entityParent === null ||
        this.state.file === "" ||
        this.state.spinner
      );
    }
  };

  closeModal = () => {
    if (!this.state.spinner) {
      this.setState({ show: false, showResult: false, errorLog: false });
      this.props.closeModal();
    }
  };

  updateType = (type) => {
    this.setState({
      entityType: type,
      parentEntityList: [],
    });
    if (type) {
      this.updateParentList(type);
    }
  };

  // qtyError = (row, qty, totalQty) => {
  //   return "".concat(
  //     "Row Num: ",
  //     row + 1,
  //     " - Available Quantity (",
  //     qty,
  //     " is greater than Total Quantity (",
  //     totalQty,
  //     " )"
  //   );
  // };

  dupError = (row, val) => {
    return "Row Num : " + (row + 1) + " - Duplicate Entity : " + val;
  };

  tempError = (type, ary) => {
    return (
      "Row Num : 1 - Please use the latest template -  " +
      type +
      " template does not support adding " +
      ary.join(", ")
    );
  };

  updateParentList = (item) => {
    if (!item) {
      return;
    }
    Api({
      sp: "getNonMaterialPaths",
      json: { id: item.EntityType_DBID },
    }).then((parents) => {
      this.setState({
        parentEntityList: [
          //{
          //  Entity_DBID: 0,
          //  EntityName: "No Parent",
          //  EntityType_DBID: 0,
          //  Path: "No Parent",
          //},
          ...parents,
        ],
        entityParent: null, // change it to -1 todo
      });
    });
  };

  handleTypeChange = (selectedOptions) => {
    this.setState({ newEntityTypes: selectedOptions });
  };
}

export default EntityBulkUpload;
