import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Api } from "./interface";

export default function EntityMove(props) {
  const [parents, setParents] = useState([]);
  const [currentParent, setCurrentParent] = useState({
    EntityName: "No Parent",
    Entity_DBID: 0,
    Path: "No Parent",
  });

  useEffect(() => {
    if (props.items.length === 1) {
      Api({
        sp: "getLocationByEntity",
        json: { id: props.items[0].Entity_DBID },
      }).then((p) => {
        const parentName = p.EntityName === p.Path ? "No Parent" : p[0].Path;
        Api({
          sp: "getNonMaterialPaths",
          json: {},
        }).then((response) => {
          response.unshift({
            EntityName: "No Parent",
            Entity_DBID: 0,
            Path: "No Parent",
          });
          setParents(
            response.filter(
              (x) =>
                x.EntityName !== parentName &&
                x.Entity_DBID !== props.items[0].Entity_DBID
            )
          );
          const parent = response.filter((x) => x.EntityName === parentName)
            .length
            ? response.filter((x) => x.EntityName === parentName)[0]
            : {
                EntityName: "No Parent",
                Entity_DBID: 0,
                Path: "No Parent",
              };
          setCurrentParent(parent);
        });
      });
    } else {
      Api({
        sp: "getNonMaterialPaths",
        json: {},
      }).then((response) => {
        response.unshift({
          EntityName: "No Parent",
          Entity_DBID: 0,
          Path: "No Parent",
        });
        setParents(response);
      });
    }
  }, []);

  const updateLocation = (newValue) => {
    // get current row id or selected row ids
    const ids = props.items.map((x) => x.Entity_DBID);
    Api({
      sp: "updateEntityLocation",
      json: {
        entityIds: ids,
        parentId: newValue.Entity_DBID,
      },
    }).then((response) => {
      if (response[0].error) {
        toast.error(response[0].error);
      } else {
        toast.success(
          (props.items.length > 1
            ? "Selected items"
            : props.items[0].EntityName) +
            " successfully moved to " +
            newValue.Path
        );
        props.close(true);
      }
    });
  };

  return parents.length ? (
    <Modal
      size="lg"
      show={props.open}
      onHide={() => props.close()}
      className="entity-modal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>
          Move{" "}
          {props.items.length > 1 ? "Selected " : props.items[0].EntityName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {parents.length ? (
          <Autocomplete
            autoHighlight={true}
            disablePortal
            id="parent-lookup"
            size="small"
            options={parents}
            disableClearable
            value={currentParent}
            sx={{ width: "100%" }}
            getOptionLabel={(option) => {
              return option.Path;
            }}
            onChange={(event, newValue) => {
              updateLocation(newValue);
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.Entity_DBID}>
                  {option.Path}
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Location" />}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  ) : null;
}
