import { IsRole } from "./validator";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Component } from "react";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import { CheckCircle, XCircle } from "react-bootstrap-icons";
import { Api } from "./interface";
import { ROWSPERPAGE } from "./constants";
import { toast } from "react-toastify";
import { getComparator, handleResize, oven } from "./common";
import { Hint } from "./constants.js";
import NoAccess from "./noAccess";
class Admin extends Component {
  constructor(props) {
    super(props);
    this.tableCellHover = [
      {
        "&:hover": {
          backgroundColor: "#BDBDBD",
        },
      },
    ];

    this.state = {
      rowsPerPage: this.bake("rowsPerPage-UserRoles")
        ? Number(this.bake("rowsPerPage-UserRoles"))
        : 15,
      searchText: this.bake("searchText-UserRoles") ?? "",

      roles: [],
      users: [],
      userRoles: [],
      rows: [],
      filteredRows: [],
      page: 0,
      order: 1,
      orderBy: -1,
      maxRows: 15,
      columns: [
        { id: "UserName", label: "Name", minWidth: 170 },
        { id: "Email", label: "Email", minWidth: 150 },
      ],
    };
  }

  bake = (g, c, v) => {
    if (typeof g === "string") {
      return oven(this.props, false, g, c);
    } else {
      return oven(this.props, true, c, v);
    }
  };

  componentDidMount = () => {
    this.getRoles();
  };

  componentDidUpdate() {
    handleResize();
  }

  closeModal = () => {
    this.setState({ currentModal: null });
  };

  getRoles = () => {
    Api({
      sp: "getRoles",
      json: {},
    }).then((response) => {
      let cols = [];

      response.forEach((x) => {
        if (x.Role === "Dev" && !IsRole(["Dev"])) {
          return; // do not push to cols array
        }

        cols.push({
          id: x.Role,
          dbId: x.Role_DBID,
          label: x.Role,
          align: "center",
        });
      });

      this.getUserRoles();

      this.setState({
        roles: response,
        columns: [...this.state.columns, ...cols],
      });

      handleResize();
    });
  };

  getUserRoles = () => {
    Api({
      sp: "getUserRoles",
      json: {},
    }).then((response) => {
      // response = response.filter((x) => x.Role !== "Dev");
      this.getUsers(response);
    });
  };

  getUsers = (userRoles) => {
    Api({
      sp: "getUsers",
      json: {},
    }).then((response) => {
      response.forEach((user) => {
        userRoles.forEach((role) => {
          if (user.OID === role.UserId) {
            user[role.Role] = 1;
          }
        });
      });

      // Filter response by searchText saved to cookies
      let filteredRows = response.filter(
        (f) =>
          f.UserName.toLowerCase().includes(
            this.state.searchText.toLowerCase()
          ) || this.state.searchText === ""
      );
      this.setState({
        users: response,
        rows: response,
        filteredRows: filteredRows,
        userRoles: userRoles,
      });
    });
  };

  createRole = () => {
    Api({
      sp: "addRole",
      json: { role: this.state.roleName },
    }).then(() => {
      this.setState({
        users: [],
        userRoles: [],
        rows: [],
        filteredRows: [],
        currentModal: null,
        roleName: null,
        columns: [{ id: "UserName", label: "Name", minWidth: 170 }],
      });
      this.getRoles();
      toast.success(this.state.roleName + " created successfully");
    });
  };

  toggleRole = (e, row, col) => {
    Api({
      sp: "toggleUserRole",
      json: { roleId: col.dbId, userId: row.OID },
    }).then((response) => {
      let rows = this.state.rows;

      rows.filter((x) => x.OID === row.OID)[0][col.label] = response.length;

      let filteredRows = rows.filter(
        (f) =>
          f.UserName.toLowerCase().includes(
            this.state.searchText.toLowerCase()
          ) || this.state.searchText === ""
      );

      this.setState({ rows: rows, filteredRows: filteredRows });

      toast.success(row.UserName + " updated successfully");
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    // Save selected rows per page to cookies
    this.bake("rowsPerPage-UserRoles", parseInt(event.target.value, 10));

    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  filterList = (filter) => {
    // Save search text to cookies & state
    this.bake("searchText-UserRoles", filter);
    this.setState({ searchText: filter });

    // Filter response by search bar text
    let filteredRows = this.state.rows.filter(
      (f) =>
        f.UserName.toLowerCase().includes(filter.toLowerCase()) || filter === ""
    );

    this.setState({ filteredRows: filteredRows, page: 0 });
  };

  render = () => {
    return IsRole(["Admin", "Dev"], this.props.roles) ? (
      <div className="main-container mt-2">
        {/* Page Title */}
        <div className="mt-2 d-flex justify-content-between">
          <h2>User Roles</h2>
          <Button
            className="addEntity mb-2"
            variant="primary"
            onClick={() => this.setState({ currentModal: "AddRole" })}
          >
            Add Role
          </Button>
        </div>

        <Stack className="pb-2" direction="horizontal" gap={3}>
          {/* Search Bar */}
          <Form.Control
            value={this.state.searchText}
            type="text"
            placeholder="Search"
            onChange={(e) => this.filterList(e.target.value)}
            autoFocus
          />
        </Stack>

        {/* Material Table */}
        <Paper sx={{ width: "100%" }}>
          <TableContainer
            id="tab-container"
            component={Paper}
            className="tableContainer"
            style={{
              maxHeight: this.state.maxRows * 53 + 57,
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              {/* Column Headers */}
              <TableHead>
                <TableRow>
                  {this.state.columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sortDirection={
                        this.state.orderBy === column.id
                          ? this.state.order
                          : false
                      }
                      className="table-header"
                    >
                      <TableSortLabel
                        active={this.state.orderBy === column.id}
                        direction={
                          this.state.orderBy === column.id
                            ? this.state.order
                            : "asc"
                        }
                        onClick={(e) => this.handleRequestSort(e, column.id)}
                      >
                        <span className="pr-2">{column.label}</span>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* Table Content */}
              <TableBody>
                {this.state.filteredRows
                  .slice()
                  .sort(getComparator(this.state.order, this.state.orderBy))
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((row, i) => {
                    return (
                      <TableRow
                        className="pointer"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={i}
                      >
                        {this.state.columns.map((column) => {
                          const value = row[column.id];
                          return column.id !== "UserName" &&
                            column.id !== "Email" ? (
                            <Hint
                              title="Click to toggle role"
                              placement="top"
                              key={column.id}
                              delay={{ show: 500, hide: 250 }}
                            >
                              {value ? (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  onClick={(e) =>
                                    this.toggleRole(e, row, column)
                                  }
                                  sx={this.tableCellHover}
                                >
                                  <CheckCircle color="green" size={18} />
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  onClick={(e) =>
                                    this.toggleRole(e, row, column)
                                  }
                                  sx={this.tableCellHover}
                                >
                                  <XCircle color="red" size={18} />
                                </TableCell>
                              )}
                            </Hint>
                          ) : (
                            <TableCell key={column.id} align={column.align}>
                              {column.id !== "UserName" &&
                              column.id !== "Email" ? (
                                <Hint
                                  title="Click to toggle role"
                                  placement="top"
                                  delay={{ show: 500, hide: 250 }}
                                >
                                  {value ? (
                                    <CheckCircle
                                      color="green"
                                      onClick={(e) =>
                                        this.toggleRole(e, row, column)
                                      }
                                      size={18}
                                    />
                                  ) : (
                                    <XCircle
                                      color="red"
                                      onClick={(e) =>
                                        this.toggleRole(e, row, column)
                                      }
                                      size={18}
                                    />
                                  )}
                                </Hint>
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage="Rows:"
            rowsPerPageOptions={ROWSPERPAGE}
            component="div"
            count={this.state.filteredRows.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>

        <Modal
          show={this.state.currentModal === "AddRole"}
          onHide={() => this.closeModal()}
          className="edge-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>Add Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="text"
              placeholder="Role Name"
              onChange={(e) => this.setState({ roleName: e.target.value })}
              autoFocus
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.createRole}
              disabled={!this.state.roleName}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    ) : (
      <NoAccess />
    );
  };
}

export default Admin;
