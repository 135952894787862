import { useEffect, useState } from "react";
import { Api } from "./interface";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { toast } from "react-toastify";

export default function DefaultFields() {
  const [fields, setFields] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentLevel, setCurrentLevel] = useState({
    Level_DBID: null,
    Level: "",
  });
  const [currentType, setCurrentType] = useState({
    EntityType_DBID: null,
    EntityType: "",
  });
  const [types, setTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  let inputRef;

  useEffect(() => {
    setSelected([]);
    setFields([]);

    setCurrentType({
      EntityType_DBID: null,
      EntityType: "",
    });
    setTypes([]);
    if (currentLevel.Level_DBID) {
      inputRef.focus();
      if (currentLevel.Level === "Material") {
        Api({
          sp: "getEntityTypesByLevel",
          json: { level: currentLevel.Level },
        }).then((response) => {
          setTypes(response);
        });
      } else {
        getMetaTypes();
      }
    }
  }, [currentLevel]);

  useEffect(() => {
    if (currentLevel.Level === "Material") {
      setFields([]);
      setSelected([]);
      if (currentType.EntityType_DBID) {
        getMetaTypes();
      }
    }
  }, [currentType]);

  const getMetaTypes = () => {
    if (currentLevel.Level !== "") {
      Api({
        sp: "getMetaTypesByLevel",
        json: {
          level: currentLevel.Level,
          type: currentType.EntityType ? currentType.EntityType : null,
        },
      }).then((response) => {
        Api({
          sp: "getDefaultFields",
          json: {},
        }).then((result) => {
          let sel = [];
          let array = [];
          if (currentLevel.Level && currentLevel.Level === "Material") {
            array = result.filter(
              (x) =>
                x.Level === currentLevel.Level &&
                x.EntityType_DBID === currentType.EntityType_DBID
            );
          }
          if (currentLevel.Level && currentLevel.Level !== "Material") {
            array = result.filter((x) => x.Level === currentLevel.Level);
          }

          array.forEach((c) => {
            response.forEach((r) => {
              if (r.EntityMetaType_DBID === c.EntityMetaType_DBID) {
                sel.push(r);
              }
            });
          });
          setSelected(sel);
          setFields(response);
        });
      });
    }
  };

  const updateFields = (event, val) => {
    if (event.key === "Backspace") {
      return;
    }
    const map = new Map(val.map((pos) => [pos.EntityMetaType_DBID, pos]));
    const uniques = [...map.values()];
    setSelected(uniques);
    updateDefaults(uniques);
  };

  const onDelete = (val) => () => {
    if (selected.length > 1) {
      setSelected((selected) =>
        selected.filter((v) => v.EntityMetaType !== val)
      );
      updateDefaults(selected.filter((v) => v.EntityMetaType !== val));
    }
  };

  const updateDefaults = (list) => {
    const ids = list.map((x) => x.EntityMetaType_DBID);
    const levelId = currentLevel.Level_DBID;
    const typeId = currentType.EntityType_DBID;
    Api({
      sp: "updateDefaultFields",
      json: { levelId: levelId, typeId: typeId, ids: ids },
    }).then(() => {
      toast.success(currentLevel.Level + " updated");
    });
  };

  useEffect(() => {
    inputRef.focus();
    setSelected([]);
    Api({
      sp: "getLevels",
      json: {},
    }).then((result) => {
      setLevels(result);
    });
  }, [currentLevel]);

  return (
    <div className="main-container">
      <div className="mt-2 d-flex justify-content-between">
        <h2>Default Fields</h2>
      </div>
      <br />
      <Autocomplete
        id="level-select"
        value={currentLevel}
        options={levels}
        autoHighlight={true}
        getOptionLabel={(option) => option.Level}
        onChange={(event, newValue) => {
          if (newValue) {
            setCurrentLevel(newValue);
          } else {
            setCurrentLevel({
              Level_DBID: null,
              Level: "",
            });
          }
        }}
        renderTags={() => null}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Levels"
            inputRef={(input) => {
              inputRef = input;
            }}
          />
        )}
      />

      {types.length > 0 ? (
        <>
          {" "}
          <br />
          <Autocomplete
            id="type-select"
            value={currentType}
            options={types}
            autoHighlight={true}
            getOptionLabel={(option) => option.EntityType}
            onChange={(event, newValue) => {
              if (newValue) {
                setCurrentType(newValue);
              } else {
                setCurrentType({
                  EntityType_DBID: null,
                  EntityType: "",
                });
              }
            }}
            renderTags={() => null}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Types" />}
          />{" "}
        </>
      ) : (
        ""
      )}

      <br />
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        value={selected}
        options={fields}
        autoHighlight={true}
        getOptionLabel={(option) => option.EntityMetaType}
        onChange={(event, newValue) => updateFields(event, newValue)}
        renderTags={() => null}
        style={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Columns" />}
        renderOption={(props, option) => {
          const { EntityMetaType, Color } = option;
          return (
            <span {...props} style={{ color: Color }}>
              {EntityMetaType}
            </span>
          );
        }}
      />
      <Box
        mt={1}
        sx={{
          "& > :not(:last-child)": { mr: 1 },
          "& > *": { mr: 1, mt: 1 },
        }}
      >
        {selected.map((v) => (
          <Chip
            key={v.EntityMetaType_DBID}
            label={v.EntityMetaType}
            onDelete={onDelete(v.EntityMetaType)}
          />
        ))}
      </Box>
    </div>
  );
}
