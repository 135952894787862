import React, { Component } from "react";
//import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Api } from "./interface";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

class EntityClone extends Component {
  constructor(props) {
    super(props);

    // const cookies = new Cookies();
    this.tableCellHover = [
      {
        "&:hover": {
          backgroundColor: "#BDBDBD",
        },
      },
    ];

    this.textInput = React.createRef();
    this.saveButton = React.createRef();
    this.commentInput = React.createRef();
    this.historyRef = React.createRef();

    this.state = {
      entities: [],
      parentEntityList: [
        { Entity_DBID: 0, EntityName: "No Parent", EntityType_DBID: 0 },
      ],
      items: [],
      filteredItems: [],
      entityTypes: [],
      template: [],
      filteredTemplate: [],
      statuses: [],
      metaOptions: [],
      fieldArray: [],
      currentEntity: {},
      currentEntityId: -1,
      statusEdit: -1,
      commenting: false,
      showHistory: true,
      comment: "",
      currentGroupChildren: [],
      currentHistory: [],
      filteredHistory: [],
      currentFilterText: "",
      filterTerm: "",
      filteredRows: [],
      rawTemplate: [],
      newEntityName: "",
      newEntityTotalQuantity: "",
      newEntityCurrentQuantity: "",
      selectedParent: { Entity_DBID: 0, Path: "Loading..." },
    };
  }

  componentDidMount() {
    Api({
      sp: "getEntityTypes",
      json: {},
    }).then((types) => {
      types = types.filter((x) => {
        return this.props.level.length
          ? x.Level === this.props.level
          : !["Material", "Location", "Site", "Geo", "Lab"].includes(x.Level);
      });
      this.updateParentList(types[0].EntityType_DBID);
      this.setState({
        entityTypes: types,
        newEntityTypeId: types[0].EntityType_DBID,
      });
    });
  }

  updateParentList = (id) => {
    if (this.props.level !== "Material" && this.props.level !== "Geo") {
      Api({
        sp: "getNonMaterialPaths",
        json: { id: id },
      }).then((parents) => {
        Api({
          sp: "getEntityParent",
          json: { entityId: this.props.item.Entity_DBID },
        }).then((response) => {
          this.setState({
            parentEntityList: [
              {
                Entity_DBID: 0,
                EntityName: "No Parent",
                EntityType_DBID: 0,
                Path: "No Parent",
              },
              ...parents,
            ],
            selectedParent: response[0],
          });
        });
      });
    } else {
      this.setState({ selectedParent: { Entity_DBID: 0, Path: "No Parent" } });
    }
  };

  addEntity = () => {
    this.props.addEntity({
      name: this.state.newEntityName,
      typeId: this.state.newEntityTypeId,
      parentId: this.state.selectedParent.Entity_DBID,
      // totalQty: this.state.newEntityTotalQuantity,
      // quantity: this.state.newEntityCurrentQuantity,
    });
  };

  setSelectedParent = (item) => {
    this.setState({
      selectedParent: item,
    });
  };

  isSaveButtonDisabled() {
    return this.state.newEntityName.length < 3 || !this.state.selectedParent;
  }

  handleCloneNameChange = (value) => {
    if (this.saveButton.current) {
      this.saveButton.current.disabled = this.isSaveButtonDisabled();
    }
    this.setState({ newEntityName: value });
  };

  cloneEntity = (edit) => {
    let item = this.props.item;
    item.newEntityName = this.state.newEntityName;
    item.parentId = this.state.selectedParent.Entity_DBID;
    Api({
      sp: "cloneEntity",
      json: {
        name: item.newEntityName,
        typeId: item.EntityType_DBID,
        cloneId: item.Entity_DBID,
        parentId: item.parentId,
      },
    }).then((response) => {
      if (response[0].error) {
        toast.error(response[0].error);
      } else {
        toast.success(
          item.EntityName + " successfully cloned as " + item.newEntityName
        );
        this.props.closeModal(edit ? response[0] : null);
      }
    });
  };

  render = () => {
    let level = this.props.level.length ? this.props.level : "Equipment";
    return (
      <Modal
        onEntered={() => this.textInput.current.focus()}
        show={this.props.open}
        onHide={this.props.close}
      >
        <Modal.Header>
          <Modal.Title>Clone {this.props.item.EntityName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            Enter new {level} name:
            <Form.Control
              ref={this.textInput}
              type="text"
              placeholder={level + " Name"}
              onChange={(e) => this.handleCloneNameChange(e.target.value)}
            />
          </div>
          {this.props.level !== "Material" && this.props.level !== "Geo" ? (
            <>
              <div className="mb-2">
                Select new {level + " "}
                Location:
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="parent-entity"
                  options={this.state.parentEntityList}
                  getOptionLabel={(option) => option.Path}
                  hiddenlabel="true"
                  value={this.state.selectedParent}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Location"
                    />
                  )}
                  onChange={(_event, newParent) => {
                    this.setSelectedParent(newParent);
                  }}
                />
              </div>{" "}
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.props.close}>
            Cancel
          </Button>
          <Button
            variant="primary"
            ref={this.saveButton}
            onClick={(e) => {
              e.currentTarget.disabled = true;
              this.cloneEntity(false);
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.currentTarget.disabled = true;
              this.cloneEntity(true);
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save And Edit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
}
export default EntityClone;
