// JavaScript source code
import React, { Component } from "react";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import { Hint } from "./constants.js";

class CollapseExpand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "close",
    };
  }
  expandCollapse(id) {
    let element = document.getElementById(id);
    if (element.classList.contains("show")) {
      element.classList.remove("show");
      this.setState({ status: "close" });
    } else {
      element.classList.add("show");
      this.setState({ status: "open" });
    }
  }
  render() {
    return (
      <Hint
        placement="top"
        delay={{ show: 250, hide: 400 }}
        title="Toggle the details"
      >
        {this.state.status === "open" ? (
          <CaretUpFill
            color="blue"
            className="pointer"
            size={24}
            onClick={() => this.expandCollapse(this.props.id)}
          ></CaretUpFill>
        ) : (
          <CaretDownFill
            color="blue"
            className="pointer"
            size={24}
            onClick={() => this.expandCollapse(this.props.id)}
          ></CaretDownFill>
        )}
      </Hint>
    );
  }
}
export default CollapseExpand;
